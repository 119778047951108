const common = {
    no_data: 'No hay datos disponibles',
    header: {
        business_name: 'Tu Negocio'
    },
    download_app: {
        text: 'Para usar la versión web de Simply, por favor utiliza un portátil o PC.',
        button: 'Descargar versión móvil'
    },
    unknown: 'Desconocido',
    request_error: 'Ocurrió un error. ¡Inténtalo de nuevo! O contacta al soporte al cliente para informar tu problema.',
    home: 'Inicio',
    product: 'Producto',
    add_product: 'Agregar Producto',
    edit_product: 'Editar Producto',
    stock_in: 'Entrada de Stock',
    Sales_Turnover: 'Volumen de Ventas',
    profit: 'Beneficio',
    stock_out: 'Salida de Stock',
    staff: 'Personal',
    stock_audit: 'Auditoría de Stock',
    button: {
        save: 'Guardar',
        save_and_add_more: 'Guardar y Agregar Más',
        submit: 'Enviar',
        done: 'Hecho',
        edit: 'Editar',
        save_edit: 'Guardar Cambios',
        yes: 'Sí',
        clear: 'Limpiar',
        delete: 'Eliminar',
        cancel: 'Cancelar',
        reset: 'Restablecer'
    },
    select_date: 'Seleccionar Fecha',
    select_action: 'Seleccionar Acción',
    mandatory: ' *(Requerido)',
    not_mandatory: '(No Requerido)',
    business: 'Negocio',
    account: 'Cuenta',
    profile: 'Perfil',
    setting: 'Configuraciones',
    whichLanguage: 'Española',
    headerDashboard: 'Conviértete en VIP',
    contact_us: 'Contáctanos',
    logout: 'Cerrar sesión',
    category: 'Categoría',
    data: 'Datos',
    history: 'Historial',
    lowStockAlert: 'Alerta de Stock Bajo',
    lowStockAlertButton: 'Agregar Cantidad de Stock de Seguridad',
    message: {
        login_success: '¡Inicio de sesión exitoso!',
        onboard_success: '¡Hurra! ¡Tu viaje ha comenzado!',
        successfully_saved: 'Datos guardados con éxito',
        successfully_deleted: 'Datos eliminados con éxito',
        error_required_field: 'El campo requerido no puede estar vacío',
        no_access: 'Sin acceso',
        error_update_history: 'Error al actualizar el historial'
    },
    feedback: 'Dar retroalimentación',
    contact: 'Contacto',
    add: 'Agregar',
    supplier: 'Proveedor',
    customer: 'Cliente',
    random: 'Aleatorio',
    scan: 'Escanear',
    enter: 'Iniciar sesión escaneando el código de barras',
    terms: {
        text: 'Al continuar, aceptas la',
        link: 'Política de Privacidad'
    },
    others: 'Otros',
    calender: {
        presets: {
            today: 'Hoy',
            yesterday: 'Ayer',
            last_7_days: 'Últimos 7 Días',
            last_30_days: 'Últimos 30 Días',
            this_month: 'Este Mes',
            last_month: 'Mes Pasado',
            custom_range: 'Rango Personalizado'
        }
    },
    print_large: 'Imprimir Grande (A4)',
    print_dot_matrix: 'Impresión Grande (Matriz de Puntos)',
    print_medium: 'Imprimir Mediano (75mm)',
    print_small: 'Imprimir Pequeño (48mm)',
    view_invoice: 'Factura',
    price: 'Precio',
    stock: 'Stock',
    quantity: 'Cantidad',
    total: 'Total',
    print_footer_left: 'Creado por',
    print_footer_center: 'Imprimido el',
    print_footer_date: 'el',
    print_to: 'Para',
    language: 'Española',
    dateStatus: 'Últimos 7 Días',
    placeholder: 'Tipo de Transacción',
    staffHistory: 'Seleccionar Personal',
    languageTitle: 'Española',
    date: 'Fecha',
    download: 'Descargar',
    download_excel: 'Descargar Excel',
    download_pdf: 'Descargar Código de Barras',
    currentTitle: 'Moneda',
    Selectperiod: 'Seleccionar periodo',
    TransactionType: 'Tipo de Transacción',
    SelectCustomer: 'Seleccionar Cliente',
    invoice: 'Factura',
    login: {
        google: 'Iniciar sesión con Google',
        apple: 'Iniciar sesión con Apple'
    },
    trusted_by: {
        title: 'Confiado por más de 50,000 negocios \n en toda Indonesia 🇮🇩'
    },
    privacyPolicyOne: 'Al iniciar sesión, aceptas la',
    privacyPolicyTwo: 'Política de Privacidad',
    privacyPolicyThree: ' de Simply.',
    manufacture: 'Fabricar'
}

export default common
