const common = {
    no_data: 'কোনো ডেটা উপলব্ধ নেই',
    header: {
        business_name: 'আপনার ব্যবসা'
    },
    download_app: {
        text: 'Simply ওয়েব সংস্করণ ব্যবহারের জন্য দয়া করে ল্যাপটপ বা পিসি ব্যবহার করুন।',
        button: 'মোবাইল সংস্করণ ডাউনলোড করুন'
    },
    unknown: 'অজানা',
    request_error:
        'একটি ত্রুটি ঘটেছে। দয়া করে আবার চেষ্টা করুন! অথবা আপনার সমস্যা রিপোর্ট করতে গ্রাহক সহায়তায় যোগাযোগ করুন।',
    home: 'বাড়ি',
    product: 'পণ্য',
    add_product: 'পণ্য যোগ করুন',
    edit_product: 'পণ্য সম্পাদনা করুন',
    stock_in: 'স্টক আসছে',
    Sales_Turnover: 'বিক্রয় টার্নওভার',
    profit: 'লাভ',
    stock_out: 'স্টক যাচ্ছে',
    staff: 'কর্মী',
    stock_audit: 'স্টক অডিট',
    button: {
        save: 'সংরক্ষণ করুন',
        save_and_add_more: 'সংরক্ষণ করুন এবং আরও যোগ করুন',
        submit: 'জমা দিন',
        done: 'সম্পন্ন',
        edit: 'সম্পাদনা করুন',
        save_edit: 'পরিবর্তনগুলি সংরক্ষণ করুন',
        yes: 'হ্যাঁ',
        clear: 'পরিষ্কার করুন',
        delete: 'মুছে ফেলুন',
        cancel: 'বাতিল করুন',
        reset: 'রিসেট করুন'
    },
    select_date: 'তারিখ নির্বাচন করুন',
    select_action: 'কার্যকলাপ নির্বাচন করুন',
    mandatory: ' *(অবশ্যই প্রয়োজন)',
    not_mandatory: '(অবশ্যই প্রয়োজন নয়)',
    business: 'ব্যবসা',
    account: 'অ্যাকাউন্ট',
    profile: 'প্রোফাইল',
    setting: 'সেটিংস',
    whichLanguage: 'বাংলা',
    headerDashboard: 'ভিআইপি হন',
    contact_us: 'আমাদের সাথে যোগাযোগ করুন',
    logout: 'লগ আউট',
    category: 'শ্রেণী',
    data: 'ডেটা',
    history: 'ইতিহাস',
    lowStockAlert: 'নিম্ন স্টকের সতর্কতা',
    lowStockAlertButton: 'নিরাপত্তা মজুতের পরিমাণ যোগ করুন',
    message: {
        login_success: 'সফলভাবে লগ ইন হয়েছে!',
        onboard_success: 'হুররে!! আপনার যাত্রা শুরু হয়েছে!',
        successfully_saved: 'ডেটা সফলভাবে সংরক্ষিত হয়েছে',
        successfully_deleted: 'ডেটা সফলভাবে মুছে ফেলা হয়েছে',
        error_required_field: 'অবশ্যই প্রয়োজনীয় ক্ষেত্র খালি থাকতে পারে না',
        no_access: 'অ্যাক্সেস নেই',
        error_update_history: 'ইতিহাস আপডেট করতে ব্যর্থ'
    },
    feedback: 'প্রতিক্রিয়া দিন',
    contact: 'যোগাযোগ করুন',
    add: 'যোগ করুন',
    supplier: 'বিক্রেতা',
    customer: 'গ্রাহক',
    random: 'র্যান্ডম',
    scan: 'স্ক্যান করুন',
    enter: 'বারকোড স্ক্যান করে লগইন করুন',
    terms: {
        text: 'কন্টিনিউ করে, আপনি সম্মত হন',
        link: 'গোপনীয়তা নীতি'
    },
    others: 'অন্যান্য',
    calender: {
        presets: {
            today: 'আজ',
            yesterday: 'গতকাল',
            last_7_days: 'গত ৭ দিন',
            last_30_days: 'গত ৩০ দিন',
            this_month: 'এই মাস',
            last_month: 'গত মাস',
            custom_range: 'কাস্টম পরিসীমা'
        }
    },
    print_large: 'বড় মুদ্রণ (A4)',
    print_dot_matrix: 'বড় প্রিন্ট (ডট ম্যাট্রিক্স)',
    print_medium: 'মাঝারি মুদ্রণ (75mm)',
    print_small: 'ছোট মুদ্রণ (48mm)',
    view_invoice: 'চালান',
    price: 'দাম',
    stock: 'স্টক',
    quantity: 'পরিমাণ',
    total: 'মোট',
    print_footer_left: 'তৈরি করেছেন',
    print_footer_center: 'মুদ্রিত তারিখ',
    print_footer_date: 'এতে',
    print_to: 'প্রতি',
    language: 'বাংলা',
    dateStatus: 'সর্বশেষ ৭ দিন',
    placeholder: 'লেনদেনের ধরন',
    staffHistory: 'কর্মকর্তা নির্বাচন করুন',
    languageTitle: 'বাংলা',
    date: 'তারিখ',
    download: 'ডাউনলোড',
    download_excel: 'এক্সেল ডাউনলোড করুন',
    download_pdf: 'বারকোড ডাউনলোড করুন',
    currentTitle: 'মুদ্রা',
    Selectperiod: 'পর্যায় নির্বাচন করুন',
    TransactionType: 'লেনদেনের ধরন',
    SelectCustomer: 'গ্রাহক নির্বাচন করুন',
    invoice: 'চালান',
    login: {
        google: 'গুগলের সাথে লগইন করুন',
        apple: 'অ্যাপলের সাথে লগইন করুন'
    },
    trusted_by: {
        title: '৫০,০০০ এরও বেশি ব্যবসার দ্বারা বিশ্বস্ত \n ইন্দোনেশিয়ার জুড়ে 🇮🇩'
    },
    privacyPolicyOne: 'সাইন ইন করার মাধ্যমে, আপনি Simply-এর',
    privacyPolicyTwo: 'গোপনীয়তা নীতি',
    privacyPolicyThree: 'মেনে নিচ্ছেন।',
    manufacture: 'উৎপাদন'
}

export default common
