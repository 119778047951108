const common = {
    no_data: 'لا توجد بيانات متاحة',
    header: {
        business_name: 'نشاطك التجاري'
    },
    download_app: {
        text: 'لاستخدام نسخة الويب من Simply، يُرجى استخدام اللابتوب أو الكمبيوتر الشخصي.',
        button: 'تحميل النسخة المحمولة'
    },
    unknown: 'غير معروف',
    request_error: 'حدث خطأ. يرجى المحاولة مرة أخرى! أو الاتصال بدعم العملاء للإبلاغ عن مشكلتك.',
    home: 'الرئيسية',
    product: 'المنتج',
    add_product: 'إضافة منتج',
    edit_product: 'تعديل المنتج',
    stock_in: 'وارد المخزون',
    Sales_Turnover: 'إيرادات المبيعات',
    profit: 'الربح',
    stock_out: 'صادر المخزون',
    staff: 'موظف',
    stock_audit: 'تدقيق المخزون',
    button: {
        save: 'حفظ',
        save_and_add_more: 'حفظ وإضافة المزيد',
        submit: 'إرسال',
        done: 'تم',
        edit: 'تعديل',
        save_edit: 'حفظ التغييرات',
        yes: 'نعم',
        clear: 'مسح',
        delete: 'حذف',
        cancel: 'إلغاء',
        reset: 'إعادة تعيين'
    },
    select_date: 'اختر التاريخ',
    select_action: 'اختر الإجراء',
    mandatory: ' *(مطلوب)',
    not_mandatory: '(ليس مطلوبًا)',
    business: 'نشاط تجاري',
    account: 'حساب',
    profile: 'الملف الشخصي',
    setting: 'الإعدادات',
    whichLanguage: 'عربي',
    headerDashboard: 'كن VIP',
    contact_us: 'اتصل بنا',
    logout: 'تسجيل الخروج',
    category: 'فئة',
    data: 'بيانات',
    history: 'تاريخ',
    lowStockAlert: 'تنبيه المخزون المنخفض',
    lowStockAlertButton: 'إضافة كمية المخزون الآمن',
    message: {
        login_success: 'تم تسجيل الدخول بنجاح!',
        onboard_success: 'رائع!! لقد بدأت رحلتك!',
        successfully_saved: 'تم حفظ البيانات بنجاح',
        successfully_deleted: 'تم حذف البيانات بنجاح',
        error_required_field: 'لا يمكن ترك الحقل المطلوب فارغًا',
        no_access: 'لا يوجد وصول',
        error_update_history: 'فشل في تحديث السجل'
    },
    feedback: 'قدم ملاحظات',
    contact: 'اتصل',
    add: 'إضافة',
    supplier: 'المورد',
    customer: 'العميل',
    random: 'عشوائي',
    scan: 'امسح',
    enter: 'تسجيل الدخول عن طريق مسح الرمز الشريطي',
    terms: {
        text: 'بالاستمرار، فإنك توافق على',
        link: 'سياسة الخصوصية'
    },
    others: 'أخرى',
    calender: {
        presets: {
            today: 'اليوم',
            yesterday: 'أمس',
            last_7_days: 'آخر 7 أيام',
            last_30_days: 'آخر 30 يومًا',
            this_month: 'هذا الشهر',
            last_month: 'الشهر الماضي',
            custom_range: 'نطاق مخصص'
        }
    },
    print_large: 'طباعة كبيرة (A4)',
    print_dot_matrix: 'طباعة كبيرة (مصفوفة نقطية)',
    print_medium: 'طباعة متوسطة (75 مم)',
    print_small: 'طباعة صغيرة (48 مم)',
    view_invoice: 'فاتورة',
    price: 'السعر',
    stock: 'المخزون',
    quantity: 'الكمية',
    total: 'الإجمالي',
    print_footer_left: 'أنشئ بواسطة',
    print_footer_center: 'تم الطباعة في',
    print_footer_date: 'في',
    print_to: 'إلى',
    language: 'عربي',
    dateStatus: 'آخر 7 أيام',
    placeholder: 'نوع المعاملة',
    staffHistory: 'اختر الموظف',
    languageTitle: 'عربي',
    date: 'التاريخ',
    download: 'تحميل',
    download_excel: 'تحميل Excel',
    download_pdf: 'تحميل الباركود',
    currentTitle: 'العملة',
    Selectperiod: 'اختر الفترة',
    TransactionType: 'نوع المعاملة',
    SelectCustomer: 'اختر العميل',
    invoice: 'فاتورة',
    login: {
        google: 'تسجيل الدخول باستخدام Google',
        apple: 'تسجيل الدخول باستخدام Apple'
    },
    trusted_by: {
        title: 'موثوق بها من قبل أكثر من 50,000 شركة \n عبر إندونيسيا 🇮🇩'
    },
    privacyPolicyOne: 'من خلال تسجيل الدخول، أنت توافق على',
    privacyPolicyTwo: 'سياسة الخصوصية',
    privacyPolicyThree: 'الخاصة بـ Simply.',
    manufacture: 'تصنيع'
}

export default common
