import IconExclamation from '~/components/Icon/IconExclamation'
import IconExclamationOutline from '~/components/Icon/IconExclamationOutline'

import ExpensesIcon from './ExpensesIcon'
import IconAcademicCap from './IconAcademicCap'
import IconAcademicCapOutline from './IconAcademicCapOutline'
import IconAdd from './IconAdd'
import IconArchive from './IconArchive'
import IconArchiveOutline from './IconArchiveOutline'
import IconArrowRight from './IconArrowRIght'
import IconBarcodeScanner from './IconBarcodeScanner'
import IconBell from './IconBell'
import IconBellOutline from './IconBellOutline'
import IconBoxCar from './IconBoxCar'
import IconCalender from './IconCalender'
import IconCategory from './IconCategory'
import IconChart from './IconChart'
import IconChartOutline from './IconChartOutline'
import IconChat from './IconChat'
import IconChatOutline from './IconChatOutline'
import IconCheck from './IconCheck'
import IconCheckCircle from './IconCheckCircle'
import IconCheckCircleOutline from './IconCheckCircleOutline'
import IconCheckOutline from './IconCheckOutline'
import IconChevronDown from './IconChevronDown'
import IconChevronDownOutline from './IconChevronDownOutline'
import IconChevronLeft from './IconChevronLeft'
import IconChevronLeftOutline from './IconChevronLeftOutline'
import IconChevronRight from './IconChevronRight'
import IconChevronRightOutline from './IconChevronRightOutline'
import IconChevronUp from './IconChevronUp'
import IconChevronUpOutline from './IconChevronUpOutline'
import IconCloseFullscreen from './IconCloseFullscreen'
import IconCog from './IconCog'
import IconCopy from './IconCopy'
import IconCurrency from './IconCurrency'
import IconDatabase from './IconDatabase'
import IconDeviceMobile from './IconDeviceMobile'
import IconDeviceMobileOutline from './IconDeviceMobileOutline'
import IconDocumentText from './IconDocumentText'
import IconDocumentTextOutline from './IconDocumentText0utline'
import IconDotsVertical from './IconDotsVertical'
import IconDotsVerticalOutline from './IconDotsVerticalOutline'
import IconDownload from './IconDownload'
import { IconEmailOutline } from './IconEmailOutline'
import IconExpand from './IconExpand'
import IconExternalLink from './IconExternalLink'
import IconEye from './IconEye'
import IconEyeOutline from './IconEyeOutline'
import IconFileSaveOutline from './IconFileSaveOutline'
import IconHistory from './IconHistory'
import IconHome from './IconHome'
import IconHomeOutline from './IconHomeOutline'
import IconInformationCircle from './IconInformationCircle'
import IconInformationCircleOutline from './IconInformationCircleOutline'
import IconLayers from './IconLayers'
import IconLink from './IconLink'
import IconLocationMarker from './IconLocationMarker'
import IconLocationMarkerOutline from './IconLocationMarkerOutline'
import IconLockClosed from './IconLockClosed'
import IconLockClosedOutline from './IconLockClosedOutline'
import IconLogout from './IconLogout'
import IconMoney from './IconMoney'
import IconMoneyOutline from './IconMoneyOutline'
import IconNext from './IconNext'
import IconNote from './IconNote'
import IconOffice from './IconOffice'
import IconOfficeOutline from './IconOfficeOutline'
import IconOpenFullscreen from './IconOpenFullscreen'
import IconPencilAlt from './IconPencilAlt'
import IconPencilAltOutline from './IconPencilAltOutline'
import IconPlus from './IconPlus'
import IconPlusCircle from './IconPlusCircle'
import IconPlusCircleOutline from './IconPlusCircleOutline'
import IconPremiumOutline from './IconPremium'
import IconPrev from './IconPrev'
import IconProduct from './IconProduct'
import IconQuestionMarkOutline from './IconQuestionMarkOutline'
import IconReport from './IconReport'
import IconSave from './IconSave'
import IconSaveAndMore from './IconSaveAndMore'
import IconSaveStock from './IconSaveStock'
import IconSearch from './IconSearch'
import IconSearchOutline from './IconSearchOutline'
import IconSelector from './IconSelector'
import IconShoppingBag from './IconShoppingBag'
import IconShoppingBagOutline from './IconShoppingBagOutline'
import IconStaff from './IconStaff'
import IconStockAudit from './IconStockAudit'
import IconStockIn from './IconStockIn'
import IconStockOut from './IconStockOut'
import IconStore from './IconStore'
import IconSwitchBusiness from './IconSwitchBusiness'
import IconSwitchVertical from './IconSwitchVertical'
import IconTranslate from './IconTranslate'
import IconTrash from './IconTrash'
import IconTrashOutline from './IconTrashOutline'
import IconUpload from './IconUpload'
import IconUser from './IconUser'
import IconUserAdd from './IconUserAdd'
import IconUserAddOutline from './IconUserAddOutline'
import IconUserCircle from './IconUserCircle'
import IconUserCircleOutline from './IconUserCircleOutline'
import IconUserGroup from './IconUserGroup'
import IconUsers from './IconUsers'
import IconUsersOutline from './IconUsersOutline'
import IconWand from './IconWand'
import { IconWhatsApp } from './IconWhatsApp'
import IconX from './IconX'
import IconXCircle from './IconXCircle'
import IconXCircleOutline from './IconXCircleOutline'
import IconXOctagon from './IconXOctagon'
import IconXOutline from './IconXOutline'
import IconXSquare from './IconXSquareOutline'
import LowStockAlert from './LowStockAlert'
import Manufacture from './Manufacture'
import IconQuestionMark from './QuestionMark'

interface IconProps {
    /**
     * Icon name
     */
    name?: keyof typeof iconMap
    /**
     *  Size of icons
     */
    size?: keyof typeof iconSizeMap
    /**
     * Icon color
     */
    color?: string
    /**
     * Custom icon width
     */
    width?: number
    /**
     * Custom icon height
     */
    height?: number
    /**
     * Optional on click event
     */
    onClick?: () => void
}

const Icon = ({ name = '', size = 'md', color, width, height, onClick }: IconProps) => {
    const handleClick = () => {
        if (!onClick) {
            return
        }

        onClick()
    }

    return (
        <span
            className={`inline-flex items-center justify-center flex-shrink-0 ${iconSizeMap[size]}`}
            style={{ color, width: width, height: height || width }}
            role={!!onClick ? 'button' : undefined}
            onClick={() => handleClick()}>
            {iconMap[name]}
        </span>
    )
}

export const iconSizeMap = {
    xs: 'w-2 h-2',
    sm: 'w-3 h-3',
    md: 'w-4 h-4',
    lg: 'w-5 h-5',
    xl: 'w-6 h-6'
}

export const iconMap = {
    '': <></>,
    'academic-cap': <IconAcademicCap></IconAcademicCap>,
    'academic-cap-outline': <IconAcademicCapOutline></IconAcademicCapOutline>,
    add: <IconAdd></IconAdd>,
    archive: <IconArchive></IconArchive>,
    'archive-outline': <IconArchiveOutline></IconArchiveOutline>,
    'barcode-scanner': <IconBarcodeScanner></IconBarcodeScanner>,
    'arrow-right': <IconArrowRight></IconArrowRight>,
    bell: <IconBell></IconBell>,
    'bell-outline': <IconBellOutline></IconBellOutline>,
    'box-car': <IconBoxCar></IconBoxCar>,
    category: <IconCategory></IconCategory>,
    calender: <IconCalender></IconCalender>,
    chat: <IconChat></IconChat>,
    'chat-outline': <IconChatOutline></IconChatOutline>,
    chart: <IconChart></IconChart>,
    'chart-outline': <IconChartOutline></IconChartOutline>,
    check: <IconCheck></IconCheck>,
    'check-outline': <IconCheckOutline></IconCheckOutline>,
    'check-circle': <IconCheckCircle></IconCheckCircle>,
    'check-circle-outline': <IconCheckCircleOutline></IconCheckCircleOutline>,
    'chevron-down': <IconChevronDown></IconChevronDown>,
    'chevron-down-outline': <IconChevronDownOutline></IconChevronDownOutline>,
    'chevron-left': <IconChevronLeft></IconChevronLeft>,
    'chevron-left-outline': <IconChevronLeftOutline></IconChevronLeftOutline>,
    'chevron-right': <IconChevronRight></IconChevronRight>,
    'chevron-right-outline': <IconChevronRightOutline></IconChevronRightOutline>,
    'chevron-up': <IconChevronUp></IconChevronUp>,
    'chevron-up-outline': <IconChevronUpOutline></IconChevronUpOutline>,
    'close-fullscreen': <IconCloseFullscreen></IconCloseFullscreen>,
    cog: <IconCog></IconCog>,
    copy: <IconCopy></IconCopy>,
    database: <IconDatabase></IconDatabase>,
    'device-mobile': <IconDeviceMobile></IconDeviceMobile>,
    'device-mobile-outline': <IconDeviceMobileOutline></IconDeviceMobileOutline>,
    'document-text': <IconDocumentText></IconDocumentText>,
    'document-text-outline': <IconDocumentTextOutline></IconDocumentTextOutline>,
    'dots-vertical': <IconDotsVertical></IconDotsVertical>,
    'dots-vertical-outline': <IconDotsVerticalOutline></IconDotsVerticalOutline>,
    download: <IconDownload></IconDownload>,
    'email-outline': <IconEmailOutline></IconEmailOutline>,
    exclamation: <IconExclamation />,
    'exclamation-outline': <IconExclamationOutline />,
    'external-link': <IconExternalLink />,
    eye: <IconEye></IconEye>,
    'eye-outline': <IconEyeOutline></IconEyeOutline>,
    expand: <IconExpand></IconExpand>,
    'file-save-outline': <IconFileSaveOutline></IconFileSaveOutline>,
    history: <IconHistory></IconHistory>,
    home: <IconHome></IconHome>,
    'home-outline': <IconHomeOutline></IconHomeOutline>,
    'information-circle': <IconInformationCircle></IconInformationCircle>,
    'information-circle-outline': <IconInformationCircleOutline></IconInformationCircleOutline>,
    layers: <IconLayers></IconLayers>,
    link: <IconLink></IconLink>,
    'location-marker': <IconLocationMarker></IconLocationMarker>,
    'location-marker-outline': <IconLocationMarkerOutline></IconLocationMarkerOutline>,
    'lock-closed': <IconLockClosed></IconLockClosed>,
    'lock-closed-outline': <IconLockClosedOutline></IconLockClosedOutline>,
    logout: <IconLogout></IconLogout>,
    note: <IconNote></IconNote>,
    money: <IconMoney></IconMoney>,
    'money-outline': <IconMoneyOutline></IconMoneyOutline>,
    next: <IconNext></IconNext>,
    office: <IconOffice></IconOffice>,
    'office-outline': <IconOfficeOutline></IconOfficeOutline>,
    'pencil-alt': <IconPencilAlt></IconPencilAlt>,
    'pencil-alt-outline': <IconPencilAltOutline></IconPencilAltOutline>,
    'plus-circle': <IconPlusCircle></IconPlusCircle>,
    'plus-circle-outline': <IconPlusCircleOutline></IconPlusCircleOutline>,
    'premium-outline': <IconPremiumOutline></IconPremiumOutline>,
    'open-fullscreen': <IconOpenFullscreen></IconOpenFullscreen>,
    product: <IconProduct></IconProduct>,
    plus: <IconPlus></IconPlus>,
    prev: <IconPrev></IconPrev>,
    'question-mark': <IconQuestionMark></IconQuestionMark>,
    'question-mark-outline': <IconQuestionMarkOutline></IconQuestionMarkOutline>,
    report: <IconReport></IconReport>,
    save: <IconSave></IconSave>,
    'save-and-more': <IconSaveAndMore></IconSaveAndMore>,
    'save-stock': <IconSaveStock></IconSaveStock>,
    search: <IconSearch></IconSearch>,
    'search-outline': <IconSearchOutline></IconSearchOutline>,
    selector: <IconSelector></IconSelector>,
    'shopping-bag': <IconShoppingBag></IconShoppingBag>,
    'shopping-bag-outline': <IconShoppingBagOutline></IconShoppingBagOutline>,
    staff: <IconStaff></IconStaff>,
    'stock-audit': <IconStockAudit></IconStockAudit>,
    'stock-in': <IconStockIn></IconStockIn>,
    'stock-out': <IconStockOut></IconStockOut>,
    store: <IconStore></IconStore>,
    'switch-business': <IconSwitchBusiness></IconSwitchBusiness>,
    'switch-vertical': <IconSwitchVertical></IconSwitchVertical>,
    translate: <IconTranslate></IconTranslate>,
    currency: <IconCurrency></IconCurrency>,
    questionmark: <IconQuestionMark></IconQuestionMark>,
    lowstockalert: <LowStockAlert></LowStockAlert>,
    expenses: <ExpensesIcon></ExpensesIcon>,
    manufacture: <Manufacture></Manufacture>,
    trash: <IconTrash></IconTrash>,
    'trash-outline': <IconTrashOutline></IconTrashOutline>,
    upload: <IconUpload></IconUpload>,
    user: <IconUser></IconUser>,
    'user-add': <IconUserAdd></IconUserAdd>,
    'user-add-outline': <IconUserAddOutline></IconUserAddOutline>,
    'user-circle': <IconUserCircle></IconUserCircle>,
    'user-circle-outline': <IconUserCircleOutline></IconUserCircleOutline>,
    'user-group': <IconUserGroup></IconUserGroup>,
    users: <IconUsers></IconUsers>,
    'users-outline': <IconUsersOutline></IconUsersOutline>,
    x: <IconX></IconX>,
    'x-outline': <IconXOutline></IconXOutline>,
    'x-circle': <IconXCircle></IconXCircle>,
    'x-circle-outline': <IconXCircleOutline></IconXCircleOutline>,
    wand: <IconWand></IconWand>,
    whatsapp: <IconWhatsApp></IconWhatsApp>,
    'x-octagon-outline': <IconXOctagon></IconXOctagon>,
    'x-square-outline': <IconXSquare></IconXSquare>
}

export default Icon
