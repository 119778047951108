import common from './common'
import featuresPay from './feature'

const ta = {
    featuresPay,
    common,
    short: {
        finance: 'நிதி',
        stock: 'பங்கு'
    },
    onboarding: {
        welcome: 'வரவேற்கிறேன்!',
        step: {
            product: {
                title: '65% சாதிக்கப்பட்டது',
                description: 'உங்கள் பெரிய வெற்றியைத் தொடங்க 2 படிகள் மட்டுமே உள்ளன!'
            },
            stock: {
                title: '95% கிட்டத்தட்ட முடிந்தது!!',
                description: 'உங்கள் பெரிய வெற்றியைத் தொடங்க இன்னும் ஒரு படி மட்டுமே உள்ளது! இப்போது முடிக்கவும்.'
            }
        },
        business: {
            title: 'வணிகம் வெற்றிகரமாக உருவாக்கப்பட்டது!',
            description: 'அற்புதம்! உங்கள் வணிகம் பதிவுசெய்யப்பட்டுள்ளது'
        },
        product: {
            title: 'உங்கள் முதல் தயாரிப்பைச் சேர்க்கவும்',
            description: 'பங்குகளை பதிவு செய்ய தயாரிப்பைச் சேர்க்கவும்'
        },
        stock: {
            title: 'பங்கு சேர்க்க முயற்சிக்கவும்',
            description: 'வருகிற மற்றும் வெளியேறும் பங்குகளை பதிவு செய்ய தொடங்கவும்'
        },
        action: {
            add_business: {
                title: 'வணிகம் உருவாக்கவும்',
                description: 'புதிய வணிகத்தை உருவாக்க விரும்புகிறீர்களா? இங்கு கிளிக்கவும்'
            },
            join_business: {
                title: 'வணிகத்தில் சேருங்கள்',
                description: 'உங்களுக்கு அழைப்புக்குறி உள்ளதா? இங்கு சேருங்கள்'
            }
        }
    },
    business: {
        title: 'ஒரு வணிகத்தை தேர்வு செய்யவும், புதியதை உருவாக்கவும் அல்லது சேருங்கள்',
        add_business: {
            title: 'புதிய வணிகம் உருவாக்கவும்',
            name: 'வணிகத்தின் பெயர்',
            name_placeholder: 'பெயரை உள்ளிடவும்',
            category: 'வணிக வகை',
            category_placeholder: 'வகையை தேர்ந்தெடுக்கவும்',
            button: 'வணிகத்தை உருவாக்கவும்'
        },
        edit_business: {
            title: 'வணிகத்தைச் திருத்தவும்'
        },
        join_business: {
            title: 'வணிகத்தில் சேருங்கள்',
            input_code: 'கீழே உள்ள அழைப்புக்குறியை உள்ளிடவும்:',
            field_placeholder: 'அழைப்புக்குறி',
            confirm_title: 'சேருங்கள்',
            confirm_text: 'நீங்கள் குழுவின் ஒரு பகுதியாக ஆகுவீர்கள் '
        },
        no_business: 'கிடைக்கும் வணிகம் இல்லை',
        create_or_join: 'ஒரு வணிகத்தை உருவாக்கவும் அல்லது சேருங்கள்',
        message: {
            saved: 'வணிகம் வெற்றிகரமாக சேமிக்கப்பட்டது!'
        }
    },
    profile: {
        edit_profile: {
            title: 'சேலைத் திருத்தவும்',
            name: 'முழு பெயர்',
            name_placeholder: 'முழு பெயரை உள்ளிடவும்',
            email: 'மின்னஞ்சல்',
            phone_number: 'தொலைபேசி எண்'
        },
        message: {
            required_name: 'பெயர் தேவை'
        }
    },
    product: {
        choose_product: 'தயவுசெய்து தயாரிப்பை தேர்வு செய்யவும்',
        choose_product_empty: 'தயாரிப்பை தேர்வு செய்து அளவையை உள்ளிடவும்',
        search: {
            placeholder: 'பெயரால் தேடவும்',
            tooltip: 'ஸ்கேனர் திறக்கவும்'
        },
        column: {
            image: 'படம்',
            name: 'பெயர்',
            price: 'விலை',
            buying_price: 'செலவு விலை',
            selling_price: 'விற்பனை விலை',
            sku: 'SKU',
            stock: 'பங்கு',
            quantity: 'அளவு',
            latest_stock: 'மீதமுள்ள',
            product_deleted: 'தயாரிப்பு நீக்கப்பட்டது',
            info_not_found: 'தகவல் கிடைக்கவில்லை',
            expired_date: 'அவகாச தேதி'
        },
        no_product: 'தயாரிப்புகள் கிடைக்கவில்லை',
        add_product: {
            sku: 'தயாரிப்பு குறியீடு',
            sku_placeholder: 'தயாரிப்பு குறியீடு. உதாரணம்: 12345',
            name: 'தயாரிப்பு பெயர்',
            name_placeholder: 'தயாரிப்பு பெயரை உள்ளிடவும்',
            initial_stock: 'ஆரம்ப பங்கு',
            initial_stock_placeholder: 'ஆரம்ப பங்கினை உள்ளிடவும்',
            buying_price: 'செலவு விலை',
            buying_price_placeholder: 'செலவு விலையை உள்ளிடவும்',
            selling_price: 'விற்பனை விலை',
            selling_price_placeholder: 'விற்பனை விலையை உள்ளிடவும்',
            category: 'வகை',
            category_placeholder: 'வகையை தேர்வு செய்யவும்',
            add_selling_price: 'சில்லறை விலை சேர்க்கவும்',
            expired_date: 'அவகாச தேதி',
            expired_date_placeholder: 'தேதியை தேர்வு செய்யவும்',
            description: 'விளக்கம்',
            description_placeholder: 'விளக்கத்தை உள்ளிடவும்'
        },
        choose_selling_price: 'விற்பனை விலை தேர்வு செய்யவும்',
        selling_price: 'விற்பனை விலை',
        total_product: 'மொத்த தயாரிப்புகள்',
        total_quantity: 'மொத்த பங்கு',
        total_buying_price: 'மொத்த செலவு விலை',
        total_selling_price: 'மொத்த விற்பனை விலை',
        message: {
            required_name: 'தயாரிப்பு பெயர் தேவை',
            required_sku: 'SKU தேவை',
            required_category: 'வகை தேவை',
            required_quantity: 'ஆரம்ப பங்கு தேவை',
            invalid_input_qty: 'தவறான பங்கு அளவு',
            required_buying_price: 'செலவு விலை தேவை',
            required_selling_price: 'விற்பனை விலை தேவை',
            required_selling_price_name: 'விற்பனை விலை பெயர் தேவை',
            required_selling_price_price: 'விற்பனை விலை தேவை'
        }
    },
    note: {
        title: 'குறிப்புகள்',
        add: 'குறிப்பு சேர்க்கவும்',
        no_note: 'குறிப்புகள் கிடைக்கவில்லை'
    },
    settings: {
        category: 'வகை',
        language: 'மொழி',
        switch_business: 'வணிகத்தை மாற்றவும்',
        currencySelect: 'நாணயம்'
    },
    category: {
        category_list: {
            title: 'தயாரிப்பு வகைகள்'
        },
        add_category: {
            button: 'புதிய வகை',
            title: 'புதிய வகையை சேர்க்கவும்'
        },
        edit_category: {
            title: 'வகையை திருத்தவும்'
        },
        name: 'வகை பெயர்',
        name_placeholder: 'வகை பெயரை உள்ளிடவும்',
        no_category: 'வகைகள் கிடைக்கவில்லை'
    },
    staff: {
        add: 'ஊழியர் சேர்க்கவும்',
        edit: 'ஊழியரை திருத்தவும்',
        access: {
            stock: 'பங்கு',
            add_product: 'புதிய தயாரிப்பை சேர்க்கவும்',
            edit_product: 'தயாரிப்பை திருத்தவும்',
            delete_product: 'தயாரிப்பை நீக்கவும்',
            view_capital_price: 'செலவுப் பொருளின் விலை பார்க்கவும்',
            add_category: 'புதிய வகையை சேர்க்கவும்',
            edit_category: 'வகையை திருத்தவும்',
            delete_category: 'வகையை நீக்கவும்',
            add_supplier: 'புதிய வழங்குநரை சேர்க்கவும்',
            edit_supplier: 'வழங்குநரை திருத்தவும்',
            delete_supplier: 'வழங்குநரைக் கொண்டு செல்லவும்',
            add_customer: 'புதிய வாடிக்கையாளரை சேர்க்கவும்',
            edit_customer: 'வாடிக்கையாளரை திருத்தவும்',
            delete_customer: 'வாடிக்கையாளரை நீக்கவும்',
            add_staff: 'ஊழியரை சேர்க்கவும்',
            edit_staff: 'ஊழியரை திருத்தவும்',
            delete_staff: 'ஊழியரை நீக்கவும்',
            change_business_profile: 'வணிகப் профலை மாற்றவும்'
        },
        no_staff: 'ஊழியர்கள் கிடைக்கவில்லை.'
    },
    code: {
        title: 'புதிய ஊழியர் சேர்க்கப்பட்டது!',
        subtitle: 'சேர ${invitation_code} ஐப் பகிரவும்:',
        valid: 'குறி 48 மணி நேரத்திற்கு மட்டுமே செல்லுபடியாகும்',
        share: 'WhatsApp இல் பகிரவும்',
        copied: 'பகிர்ந்தது!',
        invalid: 'செல்லுபடியாகாத குறி!'
    },
    history: {
        no_history: 'எந்த வரலாறும் கிடைக்கவில்லை.',
        detail: {
            remove: 'வரலாற்றை நீக்கு',
            confirm: 'இந்த அம்சம் தற்போதைய பங்கு, வரலாறு மற்றும் அறிக்கைகளை பாதிக்கும்.'
        }
    },
    supplier: {
        add: {
            title: 'விற்பனையாளர் சேர்க்கவும்'
        },
        edit: {
            title: 'விற்பனையாளர் திருத்தம் செய்யவும்'
        },
        new: 'புதிய விற்பனையாளர்',
        no_supplier: 'விற்பனையாளர்கள் இல்லை.',
        bank: {
            title: 'வங்கி விவரங்கள்'
        },
        note: 'குறிப்புகள்',
        form: {
            name: {
                label: 'விற்பனையாளர் பெயர்',
                placeholder: 'விற்பனையாளர் பெயரை உள்ளிடவும்',
                placheolder_not_mandatory: 'பெயரை உள்ளிடவும் (ஆப్షனல்)',
                error: {
                    required: 'பெயர் காலியாக இருக்க முடியாது'
                }
            },
            contact: {
                label: 'தொடர்பு எண்',
                placeholder: '91934554432',
                error: {
                    required: 'தொடர்பு எண் காலியாக இருக்க முடியாது',
                    invalid: 'செல்லுபடியாகாத எண். நாட்டின் குறியீட்டை உள்ளிட வேண்டும்'
                }
            },
            bank_name: {
                label: 'வங்கி பெயர்',
                placeholder: 'வங்கி பெயரை உள்ளிடவும்'
            },
            bank_account_name: {
                label: 'கணக்கு உரிமையாளர் பெயர்',
                placeholder: 'கணக்கு உரிமையாளர் பெயரை உள்ளிடவும்'
            },
            bank_account_number: {
                label: 'கணக்கு எண்',
                placeholder: 'கணக்கு எண்ணை உள்ளிடவும்'
            },
            note: {
                label: 'குறிப்புகள்',
                placeholder: 'குறிப்புகளைச் சேர்க்கவும்'
            }
        },
        choose: 'விற்பனையாளரைத் தேர்ந்தெடுக்கவும்'
    },
    customer: {
        add: {
            title: 'வாடிக்கையாளரைச் சேர்க்கவும்'
        },
        edit: {
            title: 'வாடிக்கையாளரை திருத்தவும்'
        },
        new: 'புதிய வாடிக்கையாளர்',
        no_customer: 'வாடிக்கையாளர்கள் இல்லை.',
        note: 'குறிப்புகள்',
        form: {
            name: {
                label: 'வாடிக்கையாளர் பெயர்',
                placeholder: 'வாடிக்கையாளர் பெயரை உள்ளிடவும்',
                placheolder_not_mandatory: 'பெயரை உள்ளிடவும் (ஆப்ஷனல்)',
                error: {
                    required: 'பெயர் காலியாக இருக்க முடியாது'
                }
            },
            contact: {
                label: 'தொடர்பு எண்',
                placeholder: '91934554432',
                error: {
                    required: 'தொடர்பு எண் காலியாக இருக்க முடியாது',
                    invalid: 'செல்லுபடியாகாத எண். நாட்டின் குறியீட்டை உள்ளிட வேண்டும்'
                }
            },
            address: {
                label: 'முகவரி',
                placeholder: 'முகவரியை உள்ளிடவும்'
            },
            note: {
                label: 'குறிப்புகள்',
                placeholder: 'குறிப்புகளைச் சேர்க்கவும்'
            }
        },
        choose: 'வாடிக்கையாளரைத் தேர்ந்தெடுக்கவும்',
        report: {
            view: 'வாடிக்கையாளர் அறிக்கையைப் பாருங்கள்',
            title: 'வாடிக்கையாளர் அறிக்கை',
            no_report: 'தேர்ந்தெடுக்கப்பட்ட தேதி மீது வாடிக்கையாளர்களுக்கான எந்தவொரு அறிக்கையும் இல்லை.',
            column: {
                name: 'பெயர்',
                total_transaction: 'மொத்த பரிமாற்றங்கள்',
                total_amount: 'மொத்த விற்பனைகள்',
                total_profit: 'மொத்த லாபம்'
            }
        }
    },
    confirm_delete: 'நீங்கள் இந்த தரவினை நீக்க விரும்புகிறீர்களா?',
    report: {
        title: 'அறிக்கை'
    },
    accounting: {
        title: 'கணக்கியல்',
        view_report: 'நிதி அறிக்கையைப் பார்க்கவும்',
        dashboard_title: 'நிதி அறிக்கை',
        stats: 'புள்ளிவிவரம்',
        transaction_report: 'பரிவர்த்தனை அறிக்கை',
        filter: {
            date: {
                placeholder: 'தேதியைத் தேர்ந்தெடுக்கவும்',
                submit: 'பயன்படுத்தவும்',
                cancel: 'வடிகட்டலை ரீசெட் செய்யவும்'
            },
            timeframe: {
                day: 'நாள்பட்ட',
                week: 'வாராந்திர',
                month: 'மாதாந்திர',
                year: 'ஆண்டாந்திர'
            }
        },
        total_sales: 'மொத்த விற்பனை',
        total_purchases: 'மொத்த கொள்முதல்',
        total_profit: 'மொத்த லாபம்',
        assets_value: 'நடப்பு சொத்து மதிப்பு',
        table: {
            date: 'தேதி',
            sales: 'விற்பனை',
            purchases: 'கொள்முதல்',
            profit: 'லாபம்'
        },
        no_data: 'தரவு கிடைக்கவில்லை.'
    },
    stock_report: {
        title: 'பங்கு அறிக்கை',
        view: 'பங்கு அறிக்கையைப் பார்க்கவும்',
        no_report: 'தேர்ந்தெடுத்த தேதி அல்லது ஸ்டாஃப்பிற்கு பங்கு பதிவுகள் இல்லை.',
        allStaffPlaceHolder: 'அனைத்து ஸ்டாஃப்',
        column: {
            total_product: 'நடப்பு தயாரிப்பு வகைகள்',
            total_product_quantity: 'மொத்த நடப்பு தயாரிப்புகள்'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP அம்சம்',
            description:
                'இந்த அம்சத்திற்கான உங்களின் சோதனை காலம் முடிந்துவிட்டது. இந்த உள்ளூர் பயன்பாட்டின் மேம்பாட்டிற்கு ஆதரவளிக்க சந்தா இடுங்கள்.',
            button: 'சந்தா திட்டங்களைப் பார்க்கவும்'
        }
    },
    payment: {
        order: {
            title: 'கட்டணம்'
        }
    },
    imageUploadMessage: {
        uploadSize: 'படத்தின் அளவு 5MB-ஐ விட அதிகமாக இருக்கக் கூடாது!',
        failedMessage: 'பொருள் படத்தைப் பதிவேற்ற முடியவில்லை, தயவுசெய்து மீண்டும் முயற்சிக்கவும்!',
        successUpload: 'படம் வெற்றிகரமாக பதிவேற்றப்பட்டது!',
        failedUIError: 'படத்தை நீக்க முடியவில்லை, தயவுசெய்து மீண்டும் முயற்சிக்கவும்!',
        SuccessErrorImage: 'படம் வெற்றிகரமாக நீக்கப்பட்டது!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'தயாரிப்பு பெயர்',
        categoryLabel: 'வகை',
        BuyingPriceLabel: 'கொள்முதல் விலை',
        SellingLabel: 'விற்பனை விலை',
        StockLabel: 'பங்கு'
    },
    historyColumns: {
        Transactionlabel: 'பரிவர்த்தனை வகை',
        Locallabel: 'உள்ளூர் நேரம்',
        Createdlabel: 'உருவாக்கியவர்',
        Vendorlabel: 'விற்பனையாளர் பெயர்',
        Buyerlabel: 'கொள்முதல் தரப்பு பெயர்',
        SKUlabel: 'SKU',
        Productlabel: 'தயாரிப்பு பெயர்',
        Categorylabel: 'வகை',
        Buyinglabel: 'கொள்முதல் விலை',
        Sellinglabel: 'விற்பனை விலை',
        Initiallabel: 'ஆரம்ப பங்கு',
        Finallabel: 'இறுதி பங்கு',
        Stocklabel: 'பங்கு மாற்றம்'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'தயாரிப்பு பெயர்',
        categoryLabel: 'வகை',
        buyingPriceLabel: 'கொள்முதல் விலை',
        sellingPriceLabel: 'விற்பனை விலை',
        stockInLabel: 'பங்கு உள்ளீடு',
        stockOutLabel: 'பங்கு வெளியீடு',
        remainingStockLabel: 'மீதமுள்ள பங்கு'
    },
    accountingReportColumns: {
        labelDate: 'தேதி',
        labelSales: 'விற்பனை',
        labelPurchases: 'கொள்முதல்',
        labelProfit: 'லாபம்'
    },
    customerReportColumns: {
        labelName: 'பெயர்',
        labelTotalTransaction: 'மொத்த பரிவர்த்தனை',
        labelTotalSales: 'மொத்த விற்பனை',
        labelTotalProfit: 'மொத்த லாபம்'
    },
    scannerDialog: {
        finished: 'முடிந்தது',
        reduce: 'குறைக்கவும்',
        successfull: 'வெற்றிகரமாக',
        searching: 'தேடுகிறது...',
        title: 'ஸ்கேனர் செயல்பாட்டில் உள்ளது. பார்கோடின் மீது நோக்கவும்!'
    },
    historyFilter: {
        SelectBuyer: 'கொள்முதல் தரப்பைத் தேர்ந்தெடுக்கவும்',
        SelectVendor: 'விற்பனையாளரைத் தேர்ந்தெடுக்கவும்'
    },
    productStockFilter: {
        StockLowtoHigh: 'குறைந்த பங்கிலிருந்து அதிகம்',
        StockHightoLow: 'அதிக பங்கிலிருந்து குறைவு',
        AlphabetAZ: 'அகர வரிசை A-Z',
        AlphabetZA: 'அகர வரிசை Z-A'
    },
    minimizeTitle: 'ஸ்கேனர் செயல்பாட்டில் உள்ளது',
    security_guarantee: {
        title: 'தரவு பாதுகாப்பு உறுதி'
    },
    invoice: {
        invoicePlaceholder: 'விலைப்பட்டியல் எண்',
        invoiceTitle: 'விலைப்பட்டியல்'
    },
    stocklowreport: {
        no_stocklowreport_one: 'பாதுகாப்பு பங்கு அமைப்புகளுடன் எந்த பொருட்களும் இல்லை.',
        no_stocklowreport_two: 'தயவுசெய்து பாதுகாப்பு பங்கு அளவை சேர்க்கவும்.'
    },
    low_alert_stock: 'பாதுகாப்பு பங்கு அளவை உள்ளிடவும்',
    lowStockAlertNotify: 'பங்கு நிர்ணயிக்கப்பட்ட அளவுக்கு கீழே இருந்தால், உங்களுக்கு அறிவிப்பு வரும்.',
    applyButtonStockAlert: 'பயன்படுத்தவும்',
    cancelButtonStockAlert: 'ரத்து செய்யவும்',
    searchProductLabel: 'தயாரிப்பை தேடுக...',
    successfullyAddedSafetyStock: 'பாதுகாப்பு பங்கு வெற்றிகரமாக புதுப்பிக்கப்பட்டது',
    safety_Stock: 'பாதுகாப்பு பங்கு',
    safety_stock_placeholder: 'பாதுகாப்பு பங்குகளை உள்ளிடவும்',
    lowStockDetailsInfo: {
        ProductCode: 'தயாரிப்பு குறியீடு',
        SafetyStock: 'பாதுகாப்பு பங்கு',
        Stock: 'பங்கு',
        CostPrice: 'செலவு விலை',
        SellingPrice: 'விற்பனை விலை',
        Category: 'வகை',
        Description: 'விளக்கம்',
        ExpirationDate: 'காலாவதி தேதி'
    },
    CustomLang: {
        Attribute: 'குணம்',
        AddAttribute: 'குணம் சேர்க்கவும்',
        EditAttribute: 'குணத்தை திருத்தவும்',
        CustomLabePlaceholder: 'குணத்தின் பெயரை உள்ளிடவும்',
        CustomLabePlaceholderSelect: 'வகைத் தேர்வு செய்யவும்',
        AddMoreAttribute: 'மேலும் குணங்கள் சேர்க்கவும்',
        ItemAttribute: 'பொருள் குணம்',
        TableName: 'பெயர்',
        TableType: 'வகை',
        TableAction: 'செயல்',
        TableDelete: 'நீக்கு',
        TableEdit: 'திருத்து',
        deleteMessage: 'குணம் வெற்றிகரமாக நீக்கப்பட்டது',
        editSuccessMessage: 'குணம் வெற்றிகரமாக புதுப்பிக்கப்பட்டது',
        AddSuccessMessage: 'குணம் வெற்றிகரமாக சேர்க்கப்பட்டது',
        AddAtt: 'சேர்',
        EditAtt: 'திருத்து',
        SubmitAtt: 'சமர்ப்பிக்கப்படுகிறது...'
    },
    Expenses: {
        ExpensesTitle: 'செலவுகள்',
        AddExpenses: 'செலவுகள் சேர்க்கவும்',
        ExpenseCategory: 'செலவுத் துறை',
        NoExpenseCategory: 'எந்த செலவு வகையும் இல்லை',
        AddExpenseCategory: 'செலவுத் துறையைச் சேர்க்கவும்',
        SelectExpenseCategory: 'செலவுத் துறையைத் தேர்ந்தெடுக்கவும்',
        NoExpenses: 'எந்த செலவுகளும் இல்லை',
        ExpensesSuccess: 'செலவுகள் வெற்றிகரமாக உருவாக்கப்பட்டது',
        ExpensesSuccessEdit: 'செலவுகள் வெற்றிகரமாக புதுப்பிக்கப்பட்டது',
        ExpensesCategoryName: 'செலவுத் துறையின் பெயர்',
        ExpensesSuccessDelete: 'செலவுகள் வெற்றிகரமாக நீக்கப்பட்டது',
        TotalSpentThisWeek: 'இந்த வாரத்தில் மொத்தமாக செலவிடப்பட்டது'
    },
    Manufacturing: {
        AddManufacture: 'தயாரிப்பு விவரங்களை சேர்க்கவும்',
        Manufacturing: 'தயாரிப்பு',
        RawMaterials: 'ராவ் பொருட்கள்',
        AdditonCosts: 'அதற்கான கூடுதல் செலவுகள்',
        RawMaterial: 'ராவ் பொருள்',
        AdditonCost: 'அதற்கான கூடுதல் செலவு',
        TotalCost: 'மொத்த செலவு',
        AddRawMaterial: 'ராவ் பொருள் சேர்க்கவும்',
        AddAdditonCost: 'கூட்டுதல் செலவு சேர்க்கவும்',
        AddMore: 'மேலும் சேர்க்கவும்',
        Add: 'சேர்க்கவும்',
        Update: 'புதுப்பிக்கவும்',
        Updating: 'விவரங்களை புதுப்பிக்கப்படுகிறது...',
        ProductRequired: 'தயாரிப்பு தேவை',
        QuantityRequired: 'அளவு தேவை',
        ChargeRequired: 'சார்ஜ் தேவை',
        EstimatedCostRequired: 'கணிக்கப்பட்ட செலவு தேவை',
        PurchasePriceRequired: 'வாங்கும் விலை தேவை',
        SelectCharge: 'சார்ஜ் தேர்வு செய்யவும்',
        EstimatedCost: 'கணிக்கப்பட்ட செலவு',
        SelectProduct: 'தயாரிப்பை தேர்வு செய்யவும்',
        Quantity: 'அளவு',
        ManufactureDetails: 'தயாரிப்பு விவரங்கள்',
        BuyingPrice: 'வாங்கும் விலை',
        LabourCharge: 'உழவர் சம்பளம்',
        ElectricityCost: 'மின்சார செலவு',
        PackagingCharge: 'பேக்கேஜிங் சார்ஜ்',
        LogisticCost: 'பயண செலவு',
        OtherCharges: 'பிற செலவுகள்',
        Save: 'விவரங்களை சேமிக்கவும்',
        PurchasePrice: 'வாங்கும் விலை',
        Date: 'தேதி',
        For: 'வாசிக்க',
        PurchaseRate: 'வாங்கும் விகிதம் (விலை)',
        Scrolltoloadmore: 'மேலும் ஏற்றுவதற்கு ஸ்க்ரோல் செய்யவும்...',
        Noproductsfound: 'யாதார்த்த உற்பத்திகள் காணப்படவில்லை',
        ManufacturingQuantity: 'தயாரிப்பு அளவு',
        ManufactureAddedSuccess: 'தயாரிப்பு விவரங்கள் வெற்றிகரமாக புதுப்பிக்கப்பட்டன'
    }
}

export default ta
