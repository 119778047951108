import common from './common'
import featuresPay from './feature'

const de = {
    featuresPay,
    common,
    short: {
        finance: 'Finanzen',
        stock: 'Lager'
    },
    onboarding: {
        welcome: 'Willkommen!',
        step: {
            product: {
                title: '65% Erreicht',
                description: 'Nur noch 2 Schritte, um Ihren großen Erfolg zu starten!'
            },
            stock: {
                title: '95% Fast Fertig!!',
                description: 'Nur noch ein Schritt, um Ihren großen Erfolg zu starten! Beenden Sie es jetzt.'
            }
        },
        business: {
            title: 'Geschäft erfolgreich erstellt!',
            description: 'Großartig! Ihr Geschäft wurde registriert.'
        },
        product: {
            title: 'Fügen Sie Ihr erstes Produkt hinzu',
            description: 'Fügen Sie ein Produkt hinzu, um den Lagerbestand zu erfassen.'
        },
        stock: {
            title: 'Versuchen Sie, Lager hinzuzufügen',
            description: 'Beginnen Sie mit der Erfassung von eingehendem und ausgehendem Lager.'
        },
        action: {
            add_business: {
                title: 'Geschäft erstellen',
                description: 'Möchten Sie ein neues Geschäft erstellen? Klicken Sie hier.'
            },
            join_business: {
                title: 'Geschäft beitreten',
                description: 'Haben Sie einen Einladungscode? Treten Sie hier bei.'
            }
        }
    },
    business: {
        title: 'Wählen Sie ein Unternehmen, erstellen Sie ein neues oder treten Sie bei',
        add_business: {
            title: 'Neues Unternehmen erstellen',
            name: 'Unternehmensname',
            name_placeholder: 'Namen eingeben',
            category: 'Unternehmenscategory',
            category_placeholder: 'Kategorie auswählen',
            button: 'Unternehmen erstellen'
        },
        edit_business: {
            title: 'Unternehmen bearbeiten'
        },
        join_business: {
            title: 'Unternehmen beitreten',
            input_code: 'Geben Sie den Einladungscode unten ein:',
            field_placeholder: 'Einladungscode',
            confirm_title: 'Beitreten',
            confirm_text: 'Sie werden Teil des Teams bei '
        },
        no_business: 'Kein Unternehmen verfügbar',
        create_or_join: 'Erstellen oder einem Unternehmen beitreten',
        message: {
            saved: 'Unternehmen erfolgreich gespeichert!'
        }
    },
    profile: {
        edit_profile: {
            title: 'Profil bearbeiten',
            name: 'Vollständiger Name',
            name_placeholder: 'Vollständigen Namen eingeben',
            email: 'E-Mail',
            phone_number: 'Telefonnummer'
        },
        message: {
            required_name: 'Name ist erforderlich'
        }
    },
    product: {
        choose_product: 'Produkt wählen',
        choose_product_empty: 'Produkt auswählen und Menge eingeben',
        search: {
            placeholder: 'Nach Name suchen',
            tooltip: 'Scanner öffnen'
        },
        column: {
            image: 'Foto',
            name: 'Name',
            price: 'Preis',
            buying_price: 'Einkaufspreis',
            selling_price: 'Verkaufspreis',
            sku: 'SKU',
            stock: 'Lagerbestand',
            quantity: 'Menge',
            latest_stock: 'Verbleibend',
            product_deleted: 'Produkt wurde gelöscht',
            info_not_found: 'Information nicht verfügbar',
            expired_date: 'Ablaufdatum'
        },
        no_product: 'Keine Produkte verfügbar',
        add_product: {
            sku: 'Produktcode',
            sku_placeholder: 'Produktcode. Beispiel: 12345',
            name: 'Produktname',
            name_placeholder: 'Produktname eingeben',
            initial_stock: 'Anfangsbestand',
            initial_stock_placeholder: 'Anfangsbestand eingeben',
            buying_price: 'Einkaufspreis',
            buying_price_placeholder: 'Einkaufspreis eingeben',
            selling_price: 'Verkaufspreis',
            selling_price_placeholder: 'Verkaufspreis eingeben',
            category: 'Kategorie',
            category_placeholder: 'Kategorie auswählen',
            add_selling_price: 'Großhandelspreis hinzufügen',
            expired_date: 'Ablaufdatum',
            expired_date_placeholder: 'Datum auswählen',
            description: 'Beschreibung',
            description_placeholder: 'Beschreibung eingeben'
        },
        choose_selling_price: 'Verkaufspreis wählen',
        selling_price: 'Verkaufspreis',
        total_product: 'Gesamtprodukte',
        total_quantity: 'Gesamtlagerbestand',
        total_buying_price: 'Gesamteinkaufspreis',
        total_selling_price: 'Gesamtverkaufspreis',
        message: {
            required_name: 'Produktname ist erforderlich',
            required_sku: 'SKU ist erforderlich',
            required_category: 'Kategorie ist erforderlich',
            required_quantity: 'Anfangsbestand ist erforderlich',
            invalid_input_qty: 'Ungültige Lagerbestandsmenge',
            required_buying_price: 'Einkaufspreis ist erforderlich',
            required_selling_price: 'Verkaufspreis ist erforderlich',
            required_selling_price_name: 'Name des Verkaufspreises ist erforderlich',
            required_selling_price_price: 'Verkaufspreis ist erforderlich'
        }
    },
    note: {
        title: 'Notizen',
        add: 'Notiz hinzufügen',
        no_note: 'Keine Notizen verfügbar'
    },
    settings: {
        category: 'Kategorie',
        language: 'Sprache',
        switch_business: 'Geschäft wechseln',
        currencySelect: 'Währung'
    },
    category: {
        category_list: {
            title: 'Produktkategorien'
        },
        add_category: {
            button: 'Neue Kategorie',
            title: 'Neue Kategorie hinzufügen'
        },
        edit_category: {
            title: 'Kategorie bearbeiten'
        },
        name: 'Kategoriename',
        name_placeholder: 'Kategoriename eingeben',
        no_category: 'Keine Kategorien verfügbar'
    },
    staff: {
        add: 'Personal hinzufügen',
        edit: 'Personal bearbeiten',
        access: {
            stock: 'Bestand',
            add_product: 'Neues Produkt hinzufügen',
            edit_product: 'Produkt bearbeiten',
            delete_product: 'Produkt löschen',
            view_capital_price: 'Kostenpreis anzeigen',
            add_category: 'Neue Kategorie hinzufügen',
            edit_category: 'Kategorie bearbeiten',
            delete_category: 'Kategorie löschen',
            add_supplier: 'Neuen Anbieter hinzufügen',
            edit_supplier: 'Anbieter bearbeiten',
            delete_supplier: 'Anbieter löschen',
            add_customer: 'Neuen Kunden hinzufügen',
            edit_customer: 'Kunde bearbeiten',
            delete_customer: 'Kunden löschen',
            add_staff: 'Personal hinzufügen',
            edit_staff: 'Personal bearbeiten',
            delete_staff: 'Personal löschen',
            change_business_profile: 'Geschäftsprofil ändern'
        },
        no_staff: 'Kein Personal verfügbar.'
    },
    code: {
        title: 'Neues Personal hinzugefügt!',
        subtitle: 'Teilen Sie den Einladungscode unten, um beizutreten:',
        valid: 'Code ist nur 48 Stunden gültig',
        share: 'Auf WhatsApp teilen',
        copied: 'Kopiert!',
        invalid: 'Ungültiger Code!'
    },
    history: {
        no_history: 'Keine Historie.',
        detail: {
            remove: 'Historie entfernen',
            confirm: 'Diese Funktion wirkt sich auf den bestehenden Bestand, die Historie und die Berichte aus.'
        }
    },
    supplier: {
        add: {
            title: 'Lieferant hinzufügen'
        },
        edit: {
            title: 'Lieferant bearbeiten'
        },
        new: 'Neuer Lieferant',
        no_supplier: 'Keine Lieferanten verfügbar.',
        bank: {
            title: 'Bankdaten'
        },
        note: 'Notizen',
        form: {
            name: {
                label: 'Lieferantenname',
                placeholder: 'Lieferantenname eingeben',
                placheolder_not_mandatory: 'Name eingeben (optional)',
                error: {
                    required: 'Name darf nicht leer sein'
                }
            },
            contact: {
                label: 'Kontakt Nummer',
                placeholder: '91934554432',
                error: {
                    required: 'Kontakt Nummer darf nicht leer sein',
                    invalid: 'Ungültige Nummer. Muss Ländervorwahl enthalten'
                }
            },
            bank_name: {
                label: 'Bankname',
                placeholder: 'Bankname eingeben'
            },
            bank_account_name: {
                label: 'Kontoinhaber Name',
                placeholder: 'Kontoinhaber Name eingeben'
            },
            bank_account_number: {
                label: 'Kontonummer',
                placeholder: 'Kontonummer eingeben'
            },
            note: {
                label: 'Notizen',
                placeholder: 'Notizen hinzufügen'
            }
        },
        choose: 'Lieferanten auswählen'
    },
    customer: {
        add: {
            title: 'Kunden hinzufügen'
        },
        edit: {
            title: 'Kunden bearbeiten'
        },
        new: 'Neuer Kunde',
        no_customer: 'Keine Kunden verfügbar.',
        note: 'Notizen',
        form: {
            name: {
                label: 'Kundenname',
                placeholder: 'Kundenname eingeben',
                placheolder_not_mandatory: 'Name eingeben (optional)',
                error: {
                    required: 'Name darf nicht leer sein'
                }
            },
            contact: {
                label: 'Kontakt Nummer',
                placeholder: '91934554432',
                error: {
                    required: 'Kontakt Nummer darf nicht leer sein',
                    invalid: 'Ungültige Nummer. Muss Ländervorwahl enthalten'
                }
            },
            address: {
                label: 'Adresse',
                placeholder: 'Adresse eingeben'
            },
            note: {
                label: 'Notizen',
                placeholder: 'Notizen hinzufügen'
            }
        },
        choose: 'Käufer auswählen',
        report: {
            view: 'Käuferbericht anzeigen',
            title: 'Käuferbericht',
            no_report: 'Es gibt keine Berichte für Käufer am ausgewählten Datum.',
            column: {
                name: 'Name',
                total_transaction: 'Gesamttransaktionen',
                total_amount: 'Gesamtumsatz',
                total_profit: 'Gesamtgewinn'
            }
        }
    },
    confirm_delete: 'Sind Sie sicher, dass Sie diese Daten löschen möchten?',
    report: {
        title: 'Bericht'
    },
    accounting: {
        title: 'Buchhaltung',
        view_report: 'Finanzbericht anzeigen',
        dashboard_title: 'Finanzbericht',
        stats: 'Statistiken',
        transaction_report: 'Transaktionsbericht',
        filter: {
            date: {
                placeholder: 'Datum auswählen',
                submit: 'Anwenden',
                cancel: 'Filter zurücksetzen'
            },
            timeframe: {
                day: 'Täglich',
                week: 'Wöchentlich',
                month: 'Monatlich',
                year: 'Jährlich'
            }
        },
        total_sales: 'Gesamtumsatz',
        total_purchases: 'Gesamtkäufe',
        total_profit: 'Gesamtgewinn',
        assets_value: 'Aktueller Vermögenswert',
        table: {
            date: 'Datum',
            sales: 'Umsatz',
            purchases: 'Einkäufe',
            profit: 'Gewinn'
        },
        no_data: 'Keine Daten verfügbar.'
    },
    stock_report: {
        title: 'Bestandsbericht',
        view: 'Bestandsbericht anzeigen',
        no_report: 'Keine Bestandsaufzeichnungen für das ausgewählte Datum oder das ausgewählte Personal.',
        allStaffPlaceHolder: 'Alle Mitarbeiter',
        column: {
            total_product: 'Aktuelle Produkttypen',
            total_product_quantity: 'Gesamtanzahl der aktuellen Produkte'
        }
    },
    subscription: {
        paywall_small: {
            title: 'VIP-Funktion',
            description:
                'Ihre Testphase für diese Funktion ist abgelaufen. Unterstützen Sie die Entwicklung dieser lokalen App, indem Sie ein Abonnement abschließen.',
            button: 'Abonnementpläne anzeigen'
        }
    },
    payment: {
        order: {
            title: 'Zahlung'
        }
    },
    imageUploadMessage: {
        uploadSize: 'Die Bildgröße darf 5 MB nicht überschreiten!',
        failedMessage: 'Das Hochladen des Artikelbildes ist fehlgeschlagen, bitte versuchen Sie es erneut!',
        successUpload: 'Das Bild wurde erfolgreich hochgeladen!',
        failedUIError: 'Das Löschen des Bildes ist fehlgeschlagen, bitte versuchen Sie es erneut!',
        SuccessErrorImage: 'Das Bild wurde erfolgreich gelöscht!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'Produktname',
        categoryLabel: 'Kategorie',
        BuyingPriceLabel: 'Einkaufspreis',
        SellingLabel: 'Verkaufspreis',
        StockLabel: 'Bestand'
    },
    historyColumns: {
        Transactionlabel: 'Transaktionstyp',
        Locallabel: 'Lokale Zeit',
        Createdlabel: 'Erstellt von',
        Vendorlabel: 'Lieferantenname',
        Buyerlabel: 'Käufername',
        SKUlabel: 'SKU',
        Productlabel: 'Produktname',
        Categorylabel: 'Kategorie',
        Buyinglabel: 'Einkaufspreis',
        Sellinglabel: 'Verkaufspreis',
        Initiallabel: 'Anfangsbestand',
        Finallabel: 'Endbestand',
        Stocklabel: 'Bestandsänderung'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'Produktname',
        categoryLabel: 'Kategorie',
        buyingPriceLabel: 'Einkaufspreis',
        sellingPriceLabel: 'Verkaufspreis',
        stockInLabel: 'Eingehender Bestand',
        stockOutLabel: 'Ausgehender Bestand',
        remainingStockLabel: 'Verbleibender Bestand'
    },
    accountingReportColumns: {
        labelDate: 'Datum',
        labelSales: 'Verkäufe',
        labelPurchases: 'Einkäufe',
        labelProfit: 'Gewinn'
    },
    customerReportColumns: {
        labelName: 'Name',
        labelTotalTransaction: 'Gesamttransaktionen',
        labelTotalSales: 'Gesamtverkäufe',
        labelTotalProfit: 'Gesamtgewinn'
    },
    scannerDialog: {
        finished: 'Fertig',
        reduce: 'Reduzieren',
        successfull: 'Erfolgreich',
        searching: 'Suche...',
        title: 'Scanner aktiv. Richten Sie ihn auf den Barcode!'
    },
    historyFilter: {
        SelectBuyer: 'Käufer auswählen',
        SelectVendor: 'Lieferanten auswählen'
    },
    productStockFilter: {
        StockLowtoHigh: 'Bestand von niedrig nach hoch',
        StockHightoLow: 'Bestand von hoch nach niedrig',
        AlphabetAZ: 'Alphabet A-Z',
        AlphabetZA: 'Alphabet Z-A'
    },
    minimizeTitle: 'Scanner aktiv',
    security_guarantee: {
        title: 'Datensicherheit garantiert'
    },
    invoice: {
        invoicePlaceholder: 'Rechnungsnummer',
        invoiceTitle: 'Rechnung'
    },
    stocklowreport: {
        no_stocklowreport_one: 'Keine Artikel mit Lagerbestandsicherheits-Einstellungen vorhanden.',
        no_stocklowreport_two: 'Bitte fügen Sie die Sicherheitsmenge hinzu.'
    },
    low_alert_stock: 'Geben Sie die Sicherheitsmenge ein',
    lowStockAlertNotify: 'Sie erhalten eine Benachrichtigung, wenn der Lagerbestand unter die festgelegte Menge fällt.',
    applyButtonStockAlert: 'Anwenden',
    cancelButtonStockAlert: 'Abbrechen',
    searchProductLabel: 'Produkt suchen...',
    successfullyAddedSafetyStock: 'Sicherheitsbestand erfolgreich aktualisiert',
    safety_Stock: 'Sicherheitsbestand',
    safety_stock_placeholder: 'Sicherheitsbestand eingeben',
    lowStockDetailsInfo: {
        ProductCode: 'Produktcode',
        SafetyStock: 'Sicherheitsbestand',
        Stock: 'Bestand',
        CostPrice: 'Kostenpreis',
        SellingPrice: 'Verkaufspreis',
        Category: 'Kategorie',
        Description: 'Beschreibung',
        ExpirationDate: 'Verfallsdatum'
    },
    CustomLang: {
        Attribute: 'Attribut',
        AddAttribute: 'Attribut hinzufügen',
        EditAttribute: 'Attribut bearbeiten',
        CustomLabePlaceholder: 'Attributnamen eingeben',
        CustomLabePlaceholderSelect: 'Typ auswählen',
        AddMoreAttribute: 'Mehr Attribute hinzufügen',
        ItemAttribute: 'Artikelattribut',
        TableName: 'Name',
        TableType: 'Typ',
        TableAction: 'Aktion',
        TableDelete: 'Löschen',
        TableEdit: 'Bearbeiten',
        deleteMessage: 'Attribut erfolgreich gelöscht',
        editSuccessMessage: 'Attribut erfolgreich aktualisiert',
        AddSuccessMessage: 'Attribut erfolgreich hinzugefügt',
        AddAtt: 'Hinzufügen',
        EditAtt: 'Bearbeiten',
        SubmitAtt: 'Wird gesendet...'
    },
    Expenses: {
        ExpensesTitle: 'Ausgaben',
        AddExpenses: 'Ausgaben hinzufügen',
        ExpenseCategory: 'Ausgabenkategorie',
        NoExpenseCategory: 'Keine Ausgabenkategorie',
        AddExpenseCategory: 'Ausgabenkategorie hinzufügen',
        SelectExpenseCategory: 'Ausgabenkategorie auswählen',
        NoExpenses: 'Keine Ausgaben',
        ExpensesSuccess: 'Ausgaben erfolgreich erstellt',
        ExpensesSuccessEdit: 'Ausgaben erfolgreich aktualisiert',
        ExpensesCategoryName: 'Name der Ausgabenkategorie',
        ExpensesSuccessDelete: 'Ausgaben erfolgreich gelöscht',
        TotalSpentThisWeek: 'Gesamtbetrag diese Woche ausgegeben'
    },
    Manufacturing: {
        AddManufacture: 'Fertigungsdetails hinzufügen',
        Manufacturing: 'Fertigung',
        RawMaterials: 'Rohmaterialien',
        AdditonCosts: 'Zusätzliche Kosten',
        RawMaterial: 'Rohmaterial',
        AdditonCost: 'Zusätzliche Kosten',
        TotalCost: 'Gesamtkosten',
        AddRawMaterial: 'Rohmaterial hinzufügen für',
        AddAdditonCost: 'Zusätzliche Kosten hinzufügen für',
        AddMore: 'Mehr hinzufügen',
        Add: 'Hinzufügen',
        Update: 'Aktualisieren',
        Updating: 'Details werden aktualisiert...',
        ProductRequired: 'Produkt erforderlich',
        QuantityRequired: 'Menge erforderlich',
        ChargeRequired: 'Gebühr erforderlich',
        EstimatedCostRequired: 'Geschätzte Kosten erforderlich',
        PurchasePriceRequired: 'Kaufpreis erforderlich',
        SelectCharge: 'Gebühr auswählen',
        EstimatedCost: 'Geschätzte Kosten',
        SelectProduct: 'Produkt auswählen',
        Quantity: 'Menge',
        ManufactureDetails: 'Fertigungsdetails',
        BuyingPrice: 'Kaufpreis',
        LabourCharge: 'Arbeitskosten',
        ElectricityCost: 'Stromkosten',
        PackagingCharge: 'Verpackungsgebühr',
        LogisticCost: 'Logistikkosten',
        OtherCharges: 'Sonstige Gebühren',
        Save: 'Details speichern',
        PurchasePrice: 'Kaufpreis',
        Date: 'Datum für',
        For: 'Für',
        PurchaseRate: 'Kaufpreis (Preis)',
        Scrolltoloadmore: 'Scrollen, um mehr zu laden...',
        Noproductsfound: 'Keine Produkte gefunden',
        ManufacturingQuantity: 'Herstellungsmenge',
        ManufactureAddedSuccess: 'Fertigungsdetails wurden erfolgreich aktualisiert'
    }
}

export default de
