import common from './common'
import featuresPay from './feature'

const gu = {
    featuresPay,
    common,
    short: {
        finance: 'હિતાશી',
        stock: 'સ્ટોક'
    },
    onboarding: {
        welcome: 'સ્વાગત છે!',
        step: {
            product: {
                title: '65% પ્રાપ્ત',
                description: 'તમારી સફળતાના પ્રારંભ માટે ફક્ત 2 પગલાં બાકી છે!'
            },
            stock: {
                title: '95% લગભગ પૂર્ણ!!',
                description: 'તમારી સફળતાના પ્રારંભ માટે ફક્ત એક વધુ પગલું બાકી છે! તેને હવે પૂર્ણ કરો.'
            }
        },
        business: {
            title: 'વ્યાપાર સફળતાપૂર્વક સર્જાયો!',
            description: 'સુંદર! તમારો વ્યવસાય નોંધાયેલ છે'
        },
        product: {
            title: 'તમારો પહેલો ઉત્પાદન ઉમેરો',
            description: 'સ્ટોક નોંધવા માટે એક ઉત્પાદન ઉમેરો'
        },
        stock: {
            title: 'સ્ટોક ઉમેરવાનો પ્રયાસ કરો',
            description: 'આવતી અને જતા સ્ટોકને નોંધવાનું શરૂ કરો'
        },
        action: {
            add_business: {
                title: 'વ્યાપાર બનાવો',
                description: 'નવો વ્યવસાય બનાવવો છે? અહીં ક્લિક કરો'
            },
            join_business: {
                title: 'વ્યાપારમાં જોડાઓ',
                description: 'શું તમારી પાસે આમંત્રણ કોડ છે? અહીં જોડાઓ'
            }
        }
    },
    business: {
        title: 'એક વ્યવસાય પસંદ કરો, નવો બનાવો અથવા જોડાઓ',
        add_business: {
            title: 'નવો વ્યવસાય બનાવો',
            name: 'વ્યાપારનું નામ',
            name_placeholder: 'નામ દાખલ કરો',
            category: 'વ્યાપાર શ્રેણી',
            category_placeholder: 'શ્રેણી પસંદ કરો',
            button: 'વ્યાપાર બનાવો'
        },
        edit_business: {
            title: 'વ્યાપાર સંપાદિત કરો'
        },
        join_business: {
            title: 'વ્યાપારમાં જોડાઓ',
            input_code: 'નીચે આપેલ આમંત્રણ કોડ દાખલ કરો:',
            field_placeholder: 'આમંત્રણ કોડ',
            confirm_title: 'જોડાઓ',
            confirm_text: 'તમે ત્યાંની ટીમનો ભાગ બનશો '
        },
        no_business: 'કોઈ વ્યવસાય ઉપલબ્ધ નથી',
        create_or_join: 'વ્યાપાર બનાવો અથવા જોડાઓ',
        message: {
            saved: 'વ્યાપાર સફળતાપૂર્વક સાચવ્યો!'
        }
    },
    profile: {
        edit_profile: {
            title: 'પ્રોફાઇલ સંપાદિત કરો',
            name: 'પૂર્ણ નામ',
            name_placeholder: 'પૂર્ણ નામ દાખલ કરો',
            email: 'ઈમેલ',
            phone_number: 'ફોન નંબર'
        },
        message: {
            required_name: 'નામ આવશ્યક છે'
        }
    },
    product: {
        choose_product: 'ઉત્પાદન પસંદ કરો',
        choose_product_empty: 'ઉત્પાદન પસંદ કરો અને માત્રા દાખલ કરો',
        search: {
            placeholder: 'નામ દ્વારા શોધો',
            tooltip: 'સ્કેનર ખોલો'
        },
        column: {
            image: 'ફોટો',
            name: 'નામ',
            price: 'કિંમત',
            buying_price: 'ખરીદી કિંમત',
            selling_price: 'વેચાણ કિંમત',
            sku: 'SKU',
            stock: 'સ્ટોક',
            quantity: 'જથ્થો',
            latest_stock: 'બાકી',
            product_deleted: 'ઉત્પાદન દૂર કરાયું છે',
            info_not_found: 'માહિતી ઉપલબ્ધ નથી',
            expired_date: 'સમાપ્તિ તારીખ'
        },
        no_product: 'કોઈ ઉત્પાદન ઉપલબ્ધ નથી',
        add_product: {
            sku: 'ઉત્પાદન કોડ',
            sku_placeholder: 'ઉત્પાદન કોડ. ઉદાહરણ: 12345',
            name: 'ઉત્પાદન નામ',
            name_placeholder: 'ઉત્પાદન નામ દાખલ કરો',
            initial_stock: 'પ્રારંભિક સ્ટોક',
            initial_stock_placeholder: 'પ્રારંભિક સ્ટોક દાખલ કરો',
            buying_price: 'ખરીદી કિંમત',
            buying_price_placeholder: 'ખરીદી કિંમત દાખલ કરો',
            selling_price: 'વેચાણ કિંમત',
            selling_price_placeholder: 'વેચાણ કિંમત દાખલ કરો',
            category: 'શ્રેણી',
            category_placeholder: 'શ્રેણી પસંદ કરો',
            add_selling_price: 'થોક કિંમત ઉમેરો',
            expired_date: 'સમાપ્તિ તારીખ',
            expired_date_placeholder: 'તારીખ પસંદ કરો',
            description: 'વર્ણન',
            description_placeholder: 'વર્ણન દાખલ કરો'
        },
        choose_selling_price: 'વેચાણ કિંમત પસંદ કરો',
        selling_price: 'વેચાણ કિંમત',
        total_product: 'કુલ ઉત્પાદનો',
        total_quantity: 'કુલ સ્ટોક',
        total_buying_price: 'કુલ ખરીદી કિંમત',
        total_selling_price: 'કુલ વેચાણ કિંમત',
        message: {
            required_name: 'ઉત્પાદન નામ આવશ્યક છે',
            required_sku: 'SKU આવશ્યક છે',
            required_category: 'શ્રેણી આવશ્યક છે',
            required_quantity: 'પ્રારંભિક સ્ટોક આવશ્યક છે',
            invalid_input_qty: 'અમાન્ય સ્ટોક જથ્થો',
            required_buying_price: 'ખરીદી કિંમત આવશ્યક છે',
            required_selling_price: 'વેચાણ કિંમત આવશ્યક છે',
            required_selling_price_name: 'વેચાણ કિંમત નામ આવશ્યક છે',
            required_selling_price_price: 'વેચાણ કિંમત આવશ્યક છે'
        }
    },
    note: {
        title: 'નોટ્સ',
        add: 'નોટ ઉમેરો',
        no_note: 'કોઈ નોટ્સ ઉપલબ્ધ નથી'
    },
    settings: {
        category: 'શ્રેણી',
        language: 'ભાષા',
        switch_business: 'બિઝનેસ બદલો',
        currencySelect: 'મુદ્રા'
    },
    category: {
        category_list: {
            title: 'ઉત્પાદન શ્રેણીઓ'
        },
        add_category: {
            button: 'નવી શ્રેણી',
            title: 'નવી શ્રેણી ઉમેરો'
        },
        edit_category: {
            title: 'શ્રેણી સંપાદિત કરો'
        },
        name: 'શ્રેણીનું નામ',
        name_placeholder: 'શ્રેણીનું નામ દાખલ કરો',
        no_category: 'કોઈ શ્રેણીઓ ઉપલબ્ધ નથી'
    },
    staff: {
        add: 'સ્ટાફ ઉમેરો',
        edit: 'સ્ટાફ સંપાદિત કરો',
        access: {
            stock: 'સ્ટોક',
            add_product: 'નવી ઉત્પાદન ઉમેરો',
            edit_product: 'ઉત્પાદન સંપાદિત કરો',
            delete_product: 'ઉત્પાદન કાઢી નાખો',
            view_capital_price: 'ખર્ચ કિંમત જુઓ',
            add_category: 'નવી શ્રેણી ઉમેરો',
            edit_category: 'શ્રેણી સંપાદિત કરો',
            delete_category: 'શ્રેણી કાઢી નાખો',
            add_supplier: 'નવી પુરવઠા ઉમેરો',
            edit_supplier: 'પુરવઠા સંપાદિત કરો',
            delete_supplier: 'પુરવઠા કાઢી નાખો',
            add_customer: 'નવી ગ્રાહક ઉમેરો',
            edit_customer: 'ગ્રાહક સંપાદિત કરો',
            delete_customer: 'ગ્રાહક કાઢી નાખો',
            add_staff: 'સ્ટાફ ઉમેરો',
            edit_staff: 'સ્ટાફ સંપાદિત કરો',
            delete_staff: 'સ્ટાફ કાઢી નાખો',
            change_business_profile: 'બિઝનેસ પ્રોફાઇલ બદલો'
        },
        no_staff: 'કોઈ સ્ટાફ ઉપલબ્ધ નથી.'
    },
    code: {
        title: 'નવી સ્ટાફ ઉમેરાયેલ!',
        subtitle: 'જોડાવા માટે નીચેનો આમંત્રણ કોડ શેર કરો:',
        valid: 'કોડ 48 કલાક માટે જ માન્ય છે',
        share: 'વોટ્સએપ પર શેર કરો',
        copied: 'કોપી કરેલ!',
        invalid: 'અમાન્ય કોડ!'
    },
    history: {
        no_history: 'કોઈ ઇતિહાસ નથી.',
        detail: {
            remove: 'ઇતિહાસ દૂર કરો',
            confirm: 'આ કાર્યમાં જતેલા સ્ટોક, ઇતિહાસ અને અહેવાલો પર અસર કરશે.'
        }
    },
    supplier: {
        add: {
            title: 'વેંડર ઉમેરો'
        },
        edit: {
            title: 'વેંડર સંપાદિત કરો'
        },
        new: 'નવો વેંડર',
        no_supplier: 'કોઈ વેંડર્સ ઉપલબ્ધ નથી.',
        bank: {
            title: 'બેંક વિગતો'
        },
        note: 'નોટ્સ',
        form: {
            name: {
                label: 'વેંડર નામ',
                placeholder: 'વેંડર નામ દાખલ કરો',
                placheolder_not_mandatory: 'નામ દાખલ કરો (વૈકલ્પિક)',
                error: {
                    required: 'નામ ખાલી નથી હોઈ શકે'
                }
            },
            contact: {
                label: 'સંપર્ક નંબર',
                placeholder: '91934554432',
                error: {
                    required: 'સંપર્ક નંબર ખાલી નથી હોઈ શકે',
                    invalid: 'ખોટો નંબર. દેશ કોડ સમાવેશ કરવો અનિવાર્ય છે'
                }
            },
            bank_name: {
                label: 'બેંક નામ',
                placeholder: 'બેંક નામ દાખલ કરો'
            },
            bank_account_name: {
                label: 'ખાતાધારકનું નામ',
                placeholder: 'ખાતાધારકનું નામ દાખલ કરો'
            },
            bank_account_number: {
                label: 'ખાતાનો નંબર',
                placeholder: 'ખાતાનો નંબર દાખલ કરો'
            },
            note: {
                label: ' નોંધો',
                placeholder: 'નોંધો ઉમેરો'
            }
        },
        choose: 'વેંડર પસંદ કરો'
    },
    customer: {
        add: {
            title: 'ગ્રાહક ઉમેરો'
        },
        edit: {
            title: 'ગ્રાહક સંપાદિત કરો'
        },
        new: 'નવો ગ્રાહક',
        no_customer: 'કોઈ ગ્રાહક ઉપલબ્ધ નથી.',
        note: 'નોંધો',
        form: {
            name: {
                label: 'ગ્રાહકનું નામ',
                placeholder: 'ગ્રાહકનું નામ દાખલ કરો',
                placheolder_not_mandatory: 'નામ દાખલ કરો (ઓપ્શનલ)',
                error: {
                    required: 'નામ ખાલી નથી હોઈ શકે'
                }
            },
            contact: {
                label: 'સંપર્ક નંબર',
                placeholder: '91934554432',
                error: {
                    required: 'સંપર્ક નંબર ખાલી નથી હોઈ શકે',
                    invalid: 'અમાન્ય નંબર. દેશમાં કોડ સમાવેશ થવો જોઈએ'
                }
            },
            address: {
                label: 'સરનામું',
                placeholder: 'સરનામું દાખલ કરો'
            },
            note: {
                label: 'નોંધો',
                placeholder: 'નોંધો ઉમેરો'
            }
        },
        choose: 'ખરેદક પસંદ કરો',
        report: {
            view: 'ખરેદક રિપોર્ટ જુઓ',
            title: 'ખરેદક રિપોર્ટ',
            no_report: 'આ પસંદ કરેલ તારીખે કૃણાકો માટે કોઈ રિપોર્ટ નથી.',
            column: {
                name: 'નામ',
                total_transaction: 'કુલ વ્યવહારો',
                total_amount: 'કુલ વેચાણ',
                total_profit: 'કુલ નફો'
            }
        }
    },
    confirm_delete: 'શું તમે ખરેખર આ માહિતી કાઢી નાખવા માંગો છો?',
    report: {
        title: 'રિપોર્ટ'
    },
    accounting: {
        title: 'હિસાબકી',
        view_report: 'વિત્તીય રિપોર્ટ જુઓ',
        dashboard_title: 'વિત્તીય રિપોર્ટ',
        stats: 'આંકડા',
        transaction_report: 'લેણદેણનો રિપોર્ટ',
        filter: {
            date: {
                placeholder: 'તારીખ પસંદ કરો',
                submit: 'લાગુ કરો',
                cancel: 'ફિલ્ટર ફરી જાળવો'
            },
            timeframe: {
                day: 'દિવસે',
                week: 'સાપ્તાહિક',
                month: 'માસિક',
                year: 'વાર્ષિક'
            }
        },
        total_sales: 'કુલ વેચાણ',
        total_purchases: 'કુલ ખરીદી',
        total_profit: 'કુલ નફો',
        assets_value: 'વર્તમાન સંપત્તિ મૂલ્ય',
        table: {
            date: 'તારીખ',
            sales: 'વેચાણ',
            purchases: 'ખરીદીઓ',
            profit: 'નફો'
        },
        no_data: 'કોઈ ડેટા ઉપલબ્ધ નથી.'
    },
    stock_report: {
        title: 'સ્ટોક રિપોર્ટ',
        view: 'સ્ટોક રિપોર્ટ જુઓ',
        no_report: 'ચણેલી તારીખ અથવા સ્ટાફ માટે કોઈ સ્ટોક રેકોર્ડ નથી.',
        allStaffPlaceHolder: 'બધા સ્ટાફ',
        column: {
            total_product: 'વર્તમાન ઉત્પાદન પ્રકારો',
            total_product_quantity: 'કુલ વર્તમાન ઉત્પાદનો'
        }
    },
    subscription: {
        paywall_small: {
            title: 'વીઆઇપી સુવિધા',
            description:
                'આ સુવિધાનો તમારો ટ્રાયલ સમય સમાપ્ત થયો છે. સબ્સ્ક્રાઇબ કરીને આ સ્થાનિક એપ્લિકેશનના વિકાસને સમર્થન આપો.',
            button: 'સબ્સ્ક્રિપ્શન યોજનાઓ જુઓ'
        }
    },
    payment: {
        order: {
            title: 'ચૂકવણી'
        }
    },
    imageUploadMessage: {
        uploadSize: 'છબીનું કદ 5MBથી વધુ ન હોવું જોઈએ!',
        failedMessage: 'ઉત્પાદન છબી અપલોડ કરવામાં નિષ્ફળ, કૃપા કરીને ફરી પ્રયાસ કરો!',
        successUpload: 'છબી સફળતાપૂર્વક અપલોડ થઈ ગઈ!',
        failedUIError: 'છબી કાઢવામાં નિષ્ફળ, કૃપા કરીને ફરી પ્રયાસ કરો!',
        SuccessErrorImage: 'છબી સફળતાપૂર્વક કાઢી નાખવામાં આવી!'
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'ઉત્પાદન નામ',
        categoryLabel: 'શ્રેણી',
        BuyingPriceLabel: 'ખરીદ કિંમત',
        SellingLabel: 'વેચાણ કિંમત',
        StockLabel: 'સ્ટોક'
    },
    historyColumns: {
        Transactionlabel: 'ટ્રાન્ઝેક્શન પ્રકાર',
        Locallabel: 'સ્થાનીક સમય',
        Createdlabel: 'બનાવ્યો',
        Vendorlabel: 'વિક્રેતા નામ',
        Buyerlabel: 'ખરીદદાર નામ',
        SKUlabel: 'SKU',
        Productlabel: 'ઉત્પાદન નામ',
        Categorylabel: 'શ્રેણી',
        Buyinglabel: 'ખરીદ કિંમત',
        Sellinglabel: 'વેચાણ કિંમત',
        Initiallabel: 'પ્રારંભિક સ્ટોક',
        Finallabel: 'અંતિમ સ્ટોક',
        Stocklabel: 'સ્ટોકમાં ફેરફાર'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'ઉત્પાદન નામ',
        categoryLabel: 'શ્રેણી',
        buyingPriceLabel: 'ખરીદ કિંમત',
        sellingPriceLabel: 'વેચાણ કિંમત',
        stockInLabel: 'સ્ટોકમાં',
        stockOutLabel: 'સ્ટોક બહાર',
        remainingStockLabel: 'બાકી રહેલો સ્ટોક'
    },
    accountingReportColumns: {
        labelDate: 'તારીખ',
        labelSales: 'બેચ',
        labelPurchases: 'ખરીદીઓ',
        labelProfit: 'મુખ્ય નફો'
    },
    customerReportColumns: {
        labelName: 'નામ',
        labelTotalTransaction: 'કુલ ટ્રાન્ઝેક્શન',
        labelTotalSales: 'કુલ વેચાણ',
        labelTotalProfit: 'કુલ નફો'
    },
    scannerDialog: {
        finished: 'પૂર્ણ',
        reduce: 'કમાવો',
        successfull: 'સફળ',
        searching: 'શોધી રહ્યું છે...',
        title: 'સ્કેનર સક્રિય. બારકોડ તરફ તાળે કરો!'
    },
    historyFilter: {
        SelectBuyer: 'ખરીદનાર પસંદ કરો',
        SelectVendor: 'વિક્રેતા પસંદ કરો'
    },
    productStockFilter: {
        StockLowtoHigh: 'સ્ટોક ઓછા થી વધુ',
        StockHightoLow: 'સ્ટોક વધુ થી ઓછા',
        AlphabetAZ: 'અક્ષર A-Z',
        AlphabetZA: 'અક્ષર Z-A'
    },
    minimizeTitle: 'સ્કેનર સક્રિય',
    security_guarantee: {
        title: 'માહિતી સુરક્ષા ગેરન્ટી'
    },
    invoice: {
        invoicePlaceholder: 'ચાલાન નંબર',
        invoiceTitle: 'ચાલાન'
    },
    stocklowreport: {
        no_stocklowreport_one: 'સુરક્ષા સ્ટોક સેટિંગ્સ સાથે કોઈ આઇટમ્સ નથી.',
        no_stocklowreport_two: 'કૃપા કરીને સુરક્ષા સ્ટોકની માત્રા ઉમેરો.'
    },
    low_alert_stock: 'સુરક્ષા સ્ટોકની માત્રા દાખલ કરો',
    lowStockAlertNotify: 'જ્યારે સ્ટોક નક્કી કરેલી માત્રાથી ઓછો થશે, ત્યારે તમને સૂચના મળશે.',
    applyButtonStockAlert: 'લાગુ કરો',
    cancelButtonStockAlert: 'રદ કરો',
    searchProductLabel: 'પ્રોડક્ટ શોધો...',
    successfullyAddedSafetyStock: 'સુરક્ષા સ્ટોક સફળતાપૂર્વક અપડેટ થયો',
    safety_Stock: 'સુરક્ષા સ્ટોક',
    safety_stock_placeholder: 'સુરક્ષા સ્ટોક દાખલ કરો',
    lowStockDetailsInfo: {
        ProductCode: 'ઉત્પાદન કોડ',
        SafetyStock: 'સુરક્ષા સ્ટોક',
        Stock: 'સ્ટોક',
        CostPrice: 'લागत કિંમત',
        SellingPrice: 'વેચાણ કિંમત',
        Category: 'શ્રેણી',
        Description: 'વર્ણન',
        ExpirationDate: 'સમાપ્તિ તારીખ'
    },
    CustomLang: {
        Attribute: 'ગુણ',
        AddAttribute: 'ગુણ ઉમેરો',
        EditAttribute: 'ગુણ સંપાદિત કરો',
        CustomLabePlaceholder: 'ગુણનું નામ દાખલ કરો',
        CustomLabePlaceholderSelect: 'પ્રકાર પસંદ કરો',
        AddMoreAttribute: 'આરું વધુ ગુણ ઉમેરો',
        ItemAttribute: 'વસ્તુનું ગુણ',
        TableName: 'નામ',
        TableType: 'પ્રકાર',
        TableAction: 'ક્રિયા',
        TableDelete: 'મૂકો',
        TableEdit: 'સંપાદિત કરો',
        deleteMessage: 'ગુણ સફળતાપૂર્વક માવઠાયું',
        editSuccessMessage: 'ગુણ સફળતાપૂર્વક અપડેટ કરવામાં આવ્યો',
        AddSuccessMessage: 'ગુણ સફળતાપૂર્વક ઉમેરવામાં આવ્યો',
        AddAtt: 'ઉમેરો',
        EditAtt: 'સંપાદિત કરો',
        SubmitAtt: 'પાઠવતી રહી છે...'
    },
    Expenses: {
        ExpensesTitle: 'ખર્ચ',
        AddExpenses: 'ખર્ચ ઉમેરો',
        ExpenseCategory: 'ખર્ચની શ્રેણી',
        NoExpenseCategory: 'કોઈ ખર્ચ શ્રેણી નથી',
        AddExpenseCategory: 'ખર્ચની શ્રેણી ઉમેરો',
        SelectExpenseCategory: 'ખર્ચની શ્રેણી પસંદ કરો',
        NoExpenses: 'કોઈ ખર્ચ નથી',
        ExpensesSuccess: 'ખર્ચ સફળતાપૂર્વક બનાવાયો',
        ExpensesSuccessEdit: 'ખર્ચ સફળતાપૂર્વક અપડેટ થયો',
        ExpensesCategoryName: 'ખર્ચ શ્રેણીનું નામ',
        ExpensesSuccessDelete: 'ખર્ચ સફળતાપૂર્વક કાઢી નાખ્યો',
        TotalSpentThisWeek: 'આ અઠવાડિયે કુલ ખર્ચ'
    },
    Manufacturing: {
        AddManufacture: 'ઉત્પાદન વિગતો ઉમેરો',
        Manufacturing: 'ઉત્પાદન',
        RawMaterials: 'કાચા માલ',
        AdditonCosts: 'અથિત ખર્ચ',
        RawMaterial: 'કાચું માલ',
        AdditonCost: 'અથિત ખર્ચ',
        TotalCost: 'કુલ ખર્ચ',
        AddRawMaterial: 'કાચા માલ ઉમેરો',
        AddAdditonCost: 'અથિત ખર્ચ ઉમેરો',
        AddMore: 'આગળ ઉમેરો',
        Add: 'ઉમેરો',
        Update: 'સુધારો',
        Updating: 'વિગતો અપડેટ થઈ રહ્યા છે...',
        ProductRequired: 'ઉત્પાદન જરૂરી છે',
        QuantityRequired: 'માત્રા જરૂરી છે',
        ChargeRequired: 'ચાર્જ જરૂરી છે',
        EstimatedCostRequired: 'અંદાજિત ખર્ચ જરૂરી છે',
        PurchasePriceRequired: 'ખરીદી કિંમત જરૂરી છે',
        SelectCharge: 'ચાર્જ પસંદ કરો',
        EstimatedCost: 'અંદાજિત ખર્ચ',
        SelectProduct: 'ઉત્પાદન પસંદ કરો',
        Quantity: 'માત્રા',
        ManufactureDetails: 'ઉત્પાદન વિગતો',
        BuyingPrice: 'ખરીદી કિંમત',
        LabourCharge: 'મજૂરી ચાર્જ',
        ElectricityCost: 'વિદ્યુત ખર્ચ',
        PackagingCharge: 'પેકેજિંગ ચાર્જ',
        LogisticCost: 'લોજિસ્ટિક ખર્ચ',
        OtherCharges: 'અન્ય ચાર્જ',
        Save: 'વિગતો સાચવો',
        PurchasePrice: 'ખરીદી કિંમત',
        Date: 'તારીખ',
        For: 'માટે',
        PurchaseRate: 'ખરીદી દર (કિંમત)',
        Scrolltoloadmore: 'વધુ લોડ કરવા માટે સ્ક્રોલ કરો...',
        Noproductsfound: 'કોઈ ઉત્પાદનો મળ્યા નથી',
        ManufacturingQuantity: 'ઉત્પાદન માત્રા',
        ManufactureAddedSuccess: 'ઉત્પાદન વિગતો સફળતાપૂર્વક અપડેટ થઈ ગઈ'
    }
}

export default gu
