// Define the valid language keys
type LanguageKeys = any // Add other languages as needed

export const getPageMap = (
    localeText: LanguageKeys
): Record<
    string,
    {
        title?: string
        header?: string
        layout?: 'dashboard' | 'fullscreen'
        auth?: boolean
        hideNavigation?: boolean
        mobile?: boolean
    }
> => {
    return {
        '/login': {
            title: 'Login',
            header: '',
            layout: 'fullscreen',
            auth: false,
            mobile: true
        },
        '/maintenance': {
            title: 'Maintenance',
            header: 'Maintenance',
            layout: 'fullscreen',
            auth: false
        },
        '/business': {
            title: localeText?.common?.business,
            header: localeText?.common?.business,
            layout: 'dashboard',
            auth: true,
            mobile: true,
            hideNavigation: true
        },
        '/business/account': {
            title: localeText?.common?.account,
            header: localeText?.common?.account,
            layout: 'dashboard',
            auth: true
        },
        '/business/category': {
            title: localeText?.category?.category_list?.title,
            header: localeText?.category?.category_list?.title,
            layout: 'dashboard',
            auth: true
        },
        '/business/customer': {
            title: localeText?.common?.customer,
            header: localeText?.common?.customer,
            layout: 'dashboard',
            auth: true
        },
        '/business/customer-report': {
            title: localeText?.customer?.report?.title,
            header: localeText?.customer?.report?.title,
            layout: 'dashboard',
            auth: true,
            mobile: true
        },
        '/business/history': {
            title: localeText?.common?.history,
            header: localeText?.common?.history,
            layout: 'dashboard',
            auth: true,
            mobile: true
        },
        '/business/manufacture': {
            title: localeText?.common?.manufacture,
            header: localeText?.common?.manufacture,
            layout: 'dashboard',
            auth: true
            // mobile: true
        },
        '/business/low-stock-alert': {
            title: localeText?.common?.lowStockAlert,
            header: localeText?.common?.lowStockAlert,
            layout: 'dashboard',
            auth: true
        },
        '/business/expenses': {
            title: localeText?.Expenses?.ExpensesTitle,
            header: localeText?.Expenses?.ExpensesTitle,
            layout: 'dashboard',
            auth: true
        },
        '/business/product': {
            title: localeText?.common?.product,
            header: localeText?.common?.product,
            layout: 'dashboard',
            auth: true
        },
        '/business/product/add': {
            title: localeText?.common?.add_product,
            header: localeText?.common?.add_product,
            layout: 'dashboard',
            auth: true
        },
        '/business/product/edit': {
            title: localeText?.common?.edit_product,
            header: localeText?.common?.edit_product,
            layout: 'dashboard',
            auth: true
        },
        '/business/staff': {
            title: localeText?.common?.staff,
            header: localeText?.common?.staff,
            layout: 'dashboard',
            auth: true
        },
        '/business/staff/add': {
            title: localeText?.common?.staff,
            header: localeText?.common?.staff,
            layout: 'dashboard',
            auth: true
        },
        '/business/stock-audit': {
            title: localeText?.common?.stock_audit,
            header: localeText?.common?.stock_audit,
            layout: 'dashboard',
            auth: true
        },
        '/business/stock-in': {
            title: localeText?.common?.stock_in,
            header: localeText?.common?.stock_in,
            layout: 'dashboard',
            auth: true
        },
        '/business/stock-out': {
            title: localeText?.common?.stock_out,
            header: localeText?.common?.stock_out,
            layout: 'dashboard',
            auth: true
        },
        '/business/supplier': {
            title: localeText?.common?.supplier,
            header: localeText?.common?.supplier,
            layout: 'dashboard',
            auth: true
        },
        '/business/report': {
            title: localeText?.report?.title,
            header: localeText?.report?.title,
            layout: 'dashboard',
            auth: true,
            mobile: true
        },
        '/business/stock-report': {
            title: localeText?.stock_report?.title,
            header: localeText?.stock_report?.title,
            layout: 'dashboard',
            auth: true,
            mobile: true
        },
        '/onboarding': {
            title: localeText?.onboarding?.welcome,
            header: localeText?.onboarding?.welcome,
            layout: 'fullscreen',
            auth: true,
            hideNavigation: true,
            mobile: true
        },
        '/business/payment/order': {
            title: localeText?.payment?.order.title,
            header: localeText?.payment?.order.title,
            layout: 'fullscreen',
            auth: true,
            hideNavigation: true
        },
        '/privacy': {
            title: 'Privacy',
            header: '',
            layout: 'fullscreen',
            auth: false,
            mobile: true
        },
        '/delete-account': {
            title: 'Delete Account',
            header: '',
            layout: 'fullscreen',
            auth: false,
            mobile: true || false
        },
        '/': {
            header: 'simplystock.co',
            layout: 'fullscreen',
            auth: false
        },
        '/_error': {
            title: 'simplystock.co',
            header: 'simplystock.co',
            layout: 'fullscreen',
            auth: false
        }
    }
}
