import common from './common'
import featuresPay from './feature'

const fr = {
    featuresPay,
    common,
    short: {
        finance: 'Finance',
        stock: 'Stock'
    },
    onboarding: {
        welcome: 'Bienvenue !',
        step: {
            product: {
                title: '65 % Atteint',
                description: 'Il ne reste que 2 étapes pour commencer votre grand succès !'
            },
            stock: {
                title: '95 % Presque Fini !!',
                description: 'Il ne reste qu’une étape pour commencer votre grand succès ! Terminez-le maintenant.'
            }
        },
        business: {
            title: 'Entreprise créée avec succès !',
            description: 'Génial ! Votre entreprise a été enregistrée'
        },
        product: {
            title: 'Ajoutez votre premier produit',
            description: 'Ajoutez un produit pour commencer à enregistrer les stocks'
        },
        stock: {
            title: 'Essayez d’ajouter du stock',
            description: 'Commencez à enregistrer les entrées et sorties de stock'
        },
        action: {
            add_business: {
                title: 'Créer une Entreprise',
                description: 'Vous voulez créer une nouvelle entreprise ? Cliquez ici'
            },
            join_business: {
                title: 'Rejoindre une Entreprise',
                description: 'Vous avez un code d’invitation ? Rejoignez ici'
            }
        }
    },
    business: {
        title: 'Sélectionnez une entreprise, créez-en une nouvelle ou rejoignez-en une',
        add_business: {
            title: 'Créer une nouvelle entreprise',
            name: "Nom de l'entreprise",
            name_placeholder: 'Entrez le nom',
            category: "Catégorie d'entreprise",
            category_placeholder: 'Sélectionnez la catégorie',
            button: 'Créer une entreprise'
        },
        edit_business: {
            title: "Modifier l'entreprise"
        },
        join_business: {
            title: 'Rejoindre une entreprise',
            input_code: "Entrez le code d'invitation ci-dessous :",
            field_placeholder: "Code d'invitation",
            confirm_title: 'Rejoindre',
            confirm_text: "Vous ferez partie de l'équipe de "
        },
        no_business: 'Aucune entreprise disponible',
        create_or_join: 'Créer ou rejoindre une entreprise',
        message: {
            saved: 'Entreprise enregistrée avec succès !'
        }
    },
    profile: {
        edit_profile: {
            title: 'Modifier le profil',
            name: 'Nom complet',
            name_placeholder: 'Entrez le nom complet',
            email: 'Email',
            phone_number: 'Numéro de téléphone'
        },
        message: {
            required_name: 'Le nom est requis'
        }
    },
    product: {
        choose_product: 'Choisir le produit',
        choose_product_empty: 'Sélectionnez un produit et entrez la quantité',
        search: {
            placeholder: 'Rechercher par nom',
            tooltip: 'Ouvrir le scanner'
        },
        column: {
            image: 'Photo',
            name: 'Nom',
            price: 'Prix',
            buying_price: "Prix d'achat",
            selling_price: 'Prix de vente',
            sku: 'SKU',
            stock: 'Stock',
            quantity: 'Quantité',
            latest_stock: 'Restant',
            product_deleted: 'Le produit a été supprimé',
            info_not_found: 'Information non disponible',
            expired_date: "Date d'expiration"
        },
        no_product: 'Aucun produit disponible',
        add_product: {
            sku: 'Code produit',
            sku_placeholder: 'Code produit. Exemple : 12345',
            name: 'Nom du produit',
            name_placeholder: 'Entrez le nom du produit',
            initial_stock: 'Stock initial',
            initial_stock_placeholder: 'Entrez le stock initial',
            buying_price: "Prix d'achat",
            buying_price_placeholder: "Entrez le prix d'achat",
            selling_price: 'Prix de vente',
            selling_price_placeholder: 'Entrez le prix de vente',
            category: 'Catégorie',
            category_placeholder: 'Sélectionnez la catégorie',
            add_selling_price: 'Ajouter le prix de gros',
            expired_date: "Date d'expiration",
            expired_date_placeholder: 'Sélectionnez la date',
            description: 'Description',
            description_placeholder: 'Entrez la description'
        },
        choose_selling_price: 'Choisir le prix de vente',
        selling_price: 'Prix de vente',
        total_product: 'Total des produits',
        total_quantity: 'Total du stock',
        total_buying_price: "Prix d'achat total",
        total_selling_price: 'Prix de vente total',
        message: {
            required_name: 'Le nom du produit est requis',
            required_sku: 'Le SKU est requis',
            required_category: 'La catégorie est requise',
            required_quantity: 'Le stock initial est requis',
            invalid_input_qty: 'Quantité de stock invalide',
            required_buying_price: "Le prix d'achat est requis",
            required_selling_price: 'Le prix de vente est requis',
            required_selling_price_name: 'Le nom du prix de vente est requis',
            required_selling_price_price: 'Le prix de vente est requis'
        }
    },
    note: {
        title: 'Notes',
        add: 'Ajouter une note',
        no_note: 'Aucune note disponible'
    },
    settings: {
        category: 'Catégorie',
        language: 'Langue',
        switch_business: "Changer d'entreprise",
        currencySelect: 'Devise'
    },
    category: {
        category_list: {
            title: 'Catégories de Produits'
        },
        add_category: {
            button: 'Nouvelle Catégorie',
            title: 'Ajouter une Nouvelle Catégorie'
        },
        edit_category: {
            title: 'Modifier la Catégorie'
        },
        name: 'Nom de la Catégorie',
        name_placeholder: 'Entrez le Nom de la Catégorie',
        no_category: 'Aucune catégorie disponible'
    },
    staff: {
        add: 'Ajouter du Personnel',
        edit: 'Modifier le Personnel',
        access: {
            stock: 'Stock',
            add_product: 'Ajouter un nouveau produit',
            edit_product: 'Modifier le produit',
            delete_product: 'Supprimer le produit',
            view_capital_price: 'Voir le Prix de Coût',
            add_category: 'Ajouter une nouvelle catégorie',
            edit_category: 'Modifier la catégorie',
            delete_category: 'Supprimer la catégorie',
            add_supplier: 'Ajouter un nouveau fournisseur',
            edit_supplier: 'Modifier le fournisseur',
            delete_supplier: 'Supprimer le fournisseur',
            add_customer: 'Ajouter un nouveau client',
            edit_customer: 'Modifier le client',
            delete_customer: 'Supprimer le client',
            add_staff: 'Ajouter du personnel',
            edit_staff: 'Modifier le personnel',
            delete_staff: 'Supprimer le personnel',
            change_business_profile: "Changer le profil d'entreprise"
        },
        no_staff: 'Aucun personnel disponible.'
    },
    code: {
        title: 'Nouveau Personnel Ajouté !',
        subtitle: "Partagez le code d'invitation ci-dessous pour rejoindre :",
        valid: 'Code valable uniquement pendant 48 heures',
        share: 'Partager sur WhatsApp',
        copied: 'Copié !',
        invalid: 'Code invalide !'
    },
    history: {
        no_history: "Pas d'historique.",
        detail: {
            remove: "Supprimer l'Historique",
            confirm: "Cette fonction affectera le stock, l'historique et les rapports existants."
        }
    },
    supplier: {
        add: {
            title: 'Ajouter un Fournisseur'
        },
        edit: {
            title: 'Modifier le Fournisseur'
        },
        new: 'Nouveau fournisseur',
        no_supplier: 'Aucun fournisseur disponible.',
        bank: {
            title: 'Détails Bancaires'
        },
        note: 'Notes',
        form: {
            name: {
                label: 'Nom du Fournisseur',
                placeholder: 'Entrez le Nom du Fournisseur',
                placheolder_not_mandatory: 'Entrez le nom (facultatif)',
                error: {
                    required: 'Le nom ne peut pas être vide'
                }
            },
            contact: {
                label: 'Numéro de Contact',
                placeholder: '91934554432',
                error: {
                    required: 'Le numéro de contact ne peut pas être vide',
                    invalid: "Numéro invalide. Doit inclure l'indicatif du pays"
                }
            },
            bank_name: {
                label: 'Nom de la Banque',
                placeholder: 'Entrez le Nom de la Banque'
            },
            bank_account_name: {
                label: 'Nom du Titulaire du Compte',
                placeholder: 'Entrez le Nom du Titulaire du Compte'
            },
            bank_account_number: {
                label: 'Numéro de Compte',
                placeholder: 'Entrez le Numéro de Compte'
            },
            note: {
                label: 'Notes',
                placeholder: 'Ajouter des Notes'
            }
        },
        choose: 'Choisir un Fournisseur'
    },
    customer: {
        add: {
            title: 'Ajouter un Client'
        },
        edit: {
            title: 'Modifier le Client'
        },
        new: 'Nouveau client',
        no_customer: 'Aucun client disponible.',
        note: 'Notes',
        form: {
            name: {
                label: 'Nom du Client',
                placeholder: 'Entrez le Nom du Client',
                placheolder_not_mandatory: 'Entrez le nom (facultatif)',
                error: {
                    required: 'Le nom ne peut pas être vide'
                }
            },
            contact: {
                label: 'Numéro de Contact',
                placeholder: '91934554432',
                error: {
                    required: 'Le numéro de contact ne peut pas être vide',
                    invalid: "Numéro invalide. Doit inclure l'indicatif du pays"
                }
            },
            address: {
                label: 'Adresse',
                placeholder: "Entrez l'Adresse"
            },
            note: {
                label: 'Notes',
                placeholder: 'Ajouter des Notes'
            }
        },
        choose: 'Sélectionner un Acheteur',
        report: {
            view: "Voir le Rapport de l'Acheteur",
            title: "Rapport de l'Acheteur",
            no_report: "Il n'y a pas de rapports pour les acheteurs à la date sélectionnée.",
            column: {
                name: 'Nom',
                total_transaction: 'Total des Transactions',
                total_amount: 'Total des Ventes',
                total_profit: 'Total du Profit'
            }
        }
    },
    confirm_delete: 'Êtes-vous sûr de vouloir supprimer ces données ?',
    report: {
        title: 'Rapport'
    },
    accounting: {
        title: 'Comptabilité',
        view_report: 'Voir le Rapport Financier',
        dashboard_title: 'Rapport Financier',
        stats: 'Statistiques',
        transaction_report: 'Rapport de Transactions',
        filter: {
            date: {
                placeholder: 'Sélectionner la Date',
                submit: 'Appliquer',
                cancel: 'Réinitialiser le Filtre'
            },
            timeframe: {
                day: 'Quotidien',
                week: 'Hebdomadaire',
                month: 'Mensuel',
                year: 'Annuel'
            }
        },
        total_sales: 'Ventes Totales',
        total_purchases: 'Achats Totals',
        total_profit: 'Profit Total',
        assets_value: 'Valeur Actuelle des Actifs',
        table: {
            date: 'Date',
            sales: 'Ventes',
            purchases: 'Achats',
            profit: 'Profit'
        },
        no_data: 'Aucune donnée disponible.'
    },
    stock_report: {
        title: 'Rapport de Stock',
        view: 'Voir le Rapport de Stock',
        no_report: 'Aucun enregistrement de stock pour la date ou le personnel sélectionné.',
        allStaffPlaceHolder: 'Tout le Personnel',
        column: {
            total_product: 'Types de Produits Actuels',
            total_product_quantity: 'Total des Produits Actuels'
        }
    },
    subscription: {
        paywall_small: {
            title: 'Fonctionnalité VIP',
            description:
                "Votre période d'essai pour cette fonctionnalité est terminée. Soutenez le développement de cette application locale en vous abonnant.",
            button: "Voir les Plans d'Abonnement"
        }
    },
    payment: {
        order: {
            title: 'Paiement'
        }
    },
    imageUploadMessage: {
        uploadSize: "La taille de l'image ne doit pas dépasser 5 Mo !",
        failedMessage: "Échec du téléchargement de l'image de l'article, veuillez réessayer !",
        successUpload: "L'image a été téléchargée avec succès !",
        failedUIError: "Échec de la suppression de l'image, veuillez réessayer !",
        SuccessErrorImage: "L'image a été supprimée avec succès !"
    },
    productColumns: {
        SKULabel: 'SKU',
        nameLabel: 'Nom du Produit',
        categoryLabel: 'Catégorie',
        BuyingPriceLabel: "Prix d'Achat",
        SellingLabel: 'Prix de Vente',
        StockLabel: 'Stock'
    },
    historyColumns: {
        Transactionlabel: 'Type de Transaction',
        Locallabel: 'Heure Locale',
        Createdlabel: 'Créé Par',
        Vendorlabel: 'Nom du Fournisseur',
        Buyerlabel: "Nom de l'Acheteur",
        SKUlabel: 'SKU',
        Productlabel: 'Nom du Produit',
        Categorylabel: 'Catégorie',
        Buyinglabel: "Prix d'Achat",
        Sellinglabel: 'Prix de Vente',
        Initiallabel: 'Stock Initial',
        Finallabel: 'Stock Final',
        Stocklabel: 'Changement de Stock'
    },
    stockReportColumns: {
        skuLabel: 'SKU',
        productNameLabel: 'Nom du Produit',
        categoryLabel: 'Catégorie',
        buyingPriceLabel: "Prix d'Achat",
        sellingPriceLabel: 'Prix de Vente',
        stockInLabel: 'Stock Entrant',
        stockOutLabel: 'Stock Sortant',
        remainingStockLabel: 'Stock Restant'
    },
    accountingReportColumns: {
        labelDate: 'Date',
        labelSales: 'Ventes',
        labelPurchases: 'Achats',
        labelProfit: 'Profit'
    },
    customerReportColumns: {
        labelName: 'Nom',
        labelTotalTransaction: 'Transaction Totale',
        labelTotalSales: 'Ventes Totales',
        labelTotalProfit: 'Profit Total'
    },
    scannerDialog: {
        finished: 'Terminé',
        reduce: 'Réduire',
        successfull: 'Réussi',
        searching: 'Recherche...',
        title: 'Scanner actif. Dirigez-le vers le code-barres !'
    },
    historyFilter: {
        SelectBuyer: "Sélectionner l'Acheteur",
        SelectVendor: 'Sélectionner le Fournisseur'
    },
    productStockFilter: {
        StockLowtoHigh: 'Stock de Bas en Haut',
        StockHightoLow: 'Stock de Haut en Bas',
        AlphabetAZ: 'Alphabet A-Z',
        AlphabetZA: 'Alphabet Z-A'
    },
    minimizeTitle: 'Scanner actif',
    security_guarantee: {
        title: 'Sécurité des Données Garanties'
    },
    invoice: {
        invoicePlaceholder: 'Numéro de facture',
        invoiceTitle: 'Facture'
    },
    stocklowreport: {
        no_stocklowreport_one: 'Aucun article avec des paramètres de sécurité de stock.',
        no_stocklowreport_two: 'Veuillez ajouter la quantité de sécurité de stock.'
    },
    low_alert_stock: 'Entrez la quantité de sécurité de stock',
    lowStockAlertNotify: 'Vous recevrez une notification lorsque le stock sera inférieur à la quantité définie.',
    applyButtonStockAlert: 'Appliquer',
    cancelButtonStockAlert: 'Annuler',
    searchProductLabel: 'Rechercher un produit...',
    successfullyAddedSafetyStock: 'Stock de sécurité mis à jour avec succès',
    safety_Stock: 'Stock de Sécurité',
    safety_stock_placeholder: 'Entrez le Stock de Sécurité',
    lowStockDetailsInfo: {
        ProductCode: 'Code Produit',
        SafetyStock: 'Stock de Sécurité',
        Stock: 'Stock',
        CostPrice: 'Prix de Revient',
        SellingPrice: 'Prix de Vente',
        Category: 'Catégorie',
        Description: 'Description',
        ExpirationDate: "Date d'Expiration"
    },
    CustomLang: {
        Attribute: 'Attribut',
        AddAttribute: 'Ajouter un attribut',
        EditAttribute: 'Modifier un attribut',
        CustomLabePlaceholder: "Entrez le nom de l'attribut",
        CustomLabePlaceholderSelect: 'Sélectionner le type',
        AddMoreAttribute: 'Ajouter un autre attribut',
        ItemAttribute: "Attribut de l'élément",
        TableName: 'Nom',
        TableType: 'Type',
        TableAction: 'Action',
        TableDelete: 'Supprimer',
        TableEdit: 'Éditer',
        deleteMessage: 'Attribut supprimé avec succès',
        editSuccessMessage: 'Attribut mis à jour avec succès',
        AddSuccessMessage: 'Attribut ajouté avec succès',
        AddAtt: 'Ajouter',
        EditAtt: 'Éditer',
        SubmitAtt: 'Soumission...'
    },
    Expenses: {
        ExpensesTitle: 'Dépenses',
        AddExpenses: 'Ajouter des dépenses',
        ExpenseCategory: 'Catégorie de dépenses',
        NoExpenseCategory: 'Aucune catégorie de dépense',
        AddExpenseCategory: 'Ajouter une catégorie de dépenses',
        SelectExpenseCategory: 'Sélectionnez une catégorie de dépenses',
        NoExpenses: 'Aucune dépense',
        ExpensesSuccess: 'Dépenses créées avec succès',
        ExpensesSuccessEdit: 'Dépenses mises à jour avec succès',
        ExpensesCategoryName: 'Nom de la catégorie de dépenses',
        ExpensesSuccessDelete: 'Dépenses supprimées avec succès',
        TotalSpentThisWeek: 'Total dépensé cette semaine'
    },
    Manufacturing: {
        AddManufacture: 'Ajouter les détails de fabrication',
        Manufacturing: 'Fabrication',
        RawMaterials: 'Matériaux bruts',
        AdditonCosts: 'Coûts supplémentaires',
        RawMaterial: 'Matériau brut',
        AdditonCost: 'Coût supplémentaire',
        TotalCost: 'Coût total',
        AddRawMaterial: 'Ajouter des matières premières pour',
        AddAdditonCost: 'Ajouter un coût supplémentaire pour',
        AddMore: 'Ajouter plus',
        Add: 'Ajouter',
        Update: 'Mettre à jour',
        Updating: 'Mise à jour des détails...',
        ProductRequired: 'Produit requis',
        QuantityRequired: 'Quantité requise',
        ChargeRequired: 'Frais requis',
        EstimatedCostRequired: 'Coût estimé requis',
        PurchasePriceRequired: "Prix d'achat requis",
        SelectCharge: 'Sélectionner les frais',
        EstimatedCost: 'Coût estimé',
        SelectProduct: 'Sélectionner un produit',
        Quantity: 'Quantité',
        ManufactureDetails: 'Détails de fabrication',
        BuyingPrice: "Prix d'achat",
        LabourCharge: "Frais de main-d'œuvre",
        ElectricityCost: "Coût de l'électricité",
        PackagingCharge: "Frais d'emballage",
        LogisticCost: 'Coût logistique',
        OtherCharges: 'Autres frais',
        Save: 'Enregistrer les détails',
        PurchasePrice: "Prix d'achat",
        Date: 'Date pour',
        For: 'Pour',
        PurchaseRate: "Taux d'achat (Prix)",
        Scrolltoloadmore: 'Faites défiler pour charger plus...',
        Noproductsfound: 'Aucun produit trouvé',
        ManufacturingQuantity: 'Quantité de fabrication',
        ManufactureAddedSuccess: 'Les détails de fabrication ont été mis à jour avec succès'
    }
}

export default fr
