// redux/slices/currencySlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CurrencyState } from '../type'

// Define the initial state structure for currency
const getInitialCurrency = (): { currencySymbol: string } => {
    if (typeof window !== 'undefined') {
        const savedCurrencySymbol = localStorage.getItem('currencySymbol') || '' // Default symbol for USD
        return { currencySymbol: savedCurrencySymbol }
    }
    return { currencySymbol: '' } // Default value for server-side rendering
}

const initialState: CurrencyState = getInitialCurrency()

const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrencyState(state, action: PayloadAction<{ currencySymbol: string }>) {
            state.currencySymbol = action.payload.currencySymbol
            if (typeof window !== 'undefined') {
                localStorage.setItem('currencySymbol', action.payload.currencySymbol) // Save currency symbol to localStorage
            }
        }
    }
})

export const { setCurrencyState } = currencySlice.actions
export default currencySlice.reducer
