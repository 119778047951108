import { useRouter } from 'next/router'
import { Fragment, useEffect, useMemo, useState } from 'react'

import { LucidePlusCircle } from 'lucide-react'
import Image from 'next/image'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { useAuth } from '~/components/Auth/AuthProvider'
import { logout } from '~/components/Auth/Logout'
import { Spacer } from '~/components/Common/Spacer'
import { DropdownWrapper } from '~/components/Dropdown/DropdownWrapper'
import Icon from '~/components/Icon'
import { Sidebar } from '~/components/Sidebar/Sidebar'
import { SidebarListItem } from '~/components/Sidebar/SidebarListItem'
import { SidebarListSubitem } from '~/components/Sidebar/SidebarListSubitem'
import { useSubscription } from '~/components/Subscription/SubscriptionProvider'
import { Button } from '~/components/ui/button'
import { Select, SelectContent, SelectItem, SelectSeparator, SelectTrigger, SelectValue } from '~/components/ui/select'
import { responsiveBreakpointMap } from '~/constants'
import { COLORS } from '~/constants/colors'
import $posthog from '~/lib/posthog'
import $sentry from '~/lib/sentry'
import locale from '~/locale'
import { RootState } from '~/redux/store'
import { delayedCallback, getCurrentBusiness, setCurrentBusiness } from '~/utils'
import { DesktopPrompt } from '../DesktopPrompt'

interface DashboardProps {
    /**
     * Extend element
     */
    children: JSX.Element | JSX.Element[]
    /**
     * Curent route
     */
    pathname?: string
    /**
     * Hide navigation
     */
    hideNavigation?: boolean
    /**
     * Show Mobile version
     */
    showMobile?: boolean
}

export const Dashboard = ({ children, pathname = '', hideNavigation, showMobile = false }: DashboardProps) => {
    const language = useSelector((state: RootState) => state.language.language)
    // Assuming locale has a specific type, e.g. LocaleType
    const localeText = (locale[language as keyof typeof locale] || locale.en) as any
    const { profile, businesses, currentBusiness, checkAccess } = useAuth()

    const { execFeature, getSubscription } = useSubscription()
    const [active, setActive] = useState(false)
    const router = useRouter()
    const parentRoute = '/' + pathname.split('/')[2]
    const [showMobileSidebar] = useState(false)
    const [profileDropdownState, setProfileDropdownState] = useState(false)

    const navigationMenu = [
        // {
        //   text: locale.id.common.home,
        //   slug: '/',
        //   icon: <Icon name="home" width={22} height={22}></Icon>
        // },
        // {
        //     // text: localeText?.accounting?.dashboard_title,
        //     text: localeText?.report?.title,
        //     slug: '/report',
        //     icon: <Icon name="money-outline" width={22} height={22}></Icon>
        // },
        {
            text: localeText?.report?.title,
            slug: '/report',
            icon: <Icon name="report" width={22} height={22}></Icon>,
            submenu: [
                {
                    text: localeText?.short?.finance,
                    slug: '/report',
                    icon: <Icon name="money-outline" width={22} height={22}></Icon>
                },
                {
                    text: localeText?.short?.stock,
                    slug: '/stock-report',
                    icon: <Icon name="report" width={22} height={22}></Icon>
                },
                {
                    text: localeText?.common?.customer,
                    slug: '/customer-report'
                }
            ]
        },
        {
            text: localeText?.common?.product,
            slug: '/product',
            icon: <Icon name="archive-outline" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.manufacture,
            slug: '/manufacture',
            icon: <Icon name="manufacture" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_in,
            slug: '/stock-in',
            icon: <Icon name="download" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_out,
            slug: '/stock-out',
            icon: <Icon name="upload" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.stock_audit,
            slug: '/stock-audit',
            icon: <Icon name="switch-vertical" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.history,
            slug: '/history',
            icon: <Icon name="history" width={22} height={22}></Icon>
        },
        {
            text: localeText?.common?.contact,
            slug: '/contact',
            icon: <Icon name="user-group" width={22} height={22}></Icon>,
            submenu: [
                {
                    text: localeText?.common?.customer,
                    slug: '/customer'
                },
                {
                    text: localeText?.common?.supplier,
                    slug: '/supplier'
                },
                {
                    text: localeText?.common?.staff,
                    slug: '/staff'
                }
            ]
        },
        {
            text: localeText?.common?.lowStockAlert,
            slug: '/low-stock-alert',
            icon: <Icon name="lowstockalert" width={22} height={22}></Icon>
        },
        {
            text: localeText?.Expenses?.ExpensesTitle,
            slug: '/expenses',
            icon: <Icon name="expenses" width={22} height={22}></Icon>
        }

        // {
        //     text: locale.id.common.customer,
        //     slug: '/customer',
        //     icon: <Icon name="user-group" width={22} height={22}></Icon>
        // },
        // {
        //     text: locale.id.common.supplier,
        //     slug: '/supplier',
        //     icon: <Icon name="box-car" width={22} height={22}></Icon>
        // },
        // {
        //     text: locale.id.common.staff,
        //     slug: '/staff',
        //     icon: <Icon name="users-outline" width={22} height={22}></Icon>
        // }
        // {
        //     text: locale.id.common.category,
        //     slug: '/category',
        //     icon: <Icon name="category" width={22} height={22}></Icon>
        // },
    ]

    const [isSubscribed] = getSubscription()

    const handClickItem = (slug: string) => {
        if (slug === '') {
            return
        }

        router.push(`/business${slug}`)
    }

    const haveAccessToManageStaff = () => {
        return currentBusiness()?.roles === 'owner'
    }

    useEffect(() => {
        const business = currentBusiness()
        if (business)
            $posthog.group('business', String(business.businessId ?? ''), {
                name: business.name,
                category: business.category,
                subscription_package: business.subscription?.productId.split('_')[1] || 'free',
                subscription_start_date: business.subscription?.startDate,
                subscription_expired_date: business.subscription?.endDate
            })
    }, [currentBusiness()])

    const sidebarItems = useMemo(() => {
        return navigationMenu.map((item) => {
            if (
                item.slug === '/report' &&
                !checkAccess('readAccountingReport') &&
                !checkAccess('readStockReport') &&
                !checkAccess('readCustomerReport')
            ) {
                return <Fragment key={item.slug}></Fragment>
            }
            return (
                <SidebarListItem
                    active={item.slug === parentRoute}
                    key={item.slug}
                    icon={item.icon}
                    shrink={active}
                    onClick={() => {
                        if (item.slug === '/contact') {
                            return
                        }
                        handClickItem(item.slug)
                    }}
                    subitem={
                        item.submenu &&
                        item.submenu.map((subitem) =>
                            subitem.slug === '/report' && !checkAccess('readAccountingReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/stock-report' && !checkAccess('readStockReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/customer-report' && !checkAccess('readCustomerReport') ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : subitem.slug === '/staff' && !haveAccessToManageStaff() ? (
                                <Fragment key={subitem.slug}></Fragment>
                            ) : (
                                <SidebarListSubitem
                                    active={subitem.slug === parentRoute}
                                    key={subitem.slug}
                                    onClick={() => handClickItem(subitem.slug)}>
                                    {subitem.text}
                                </SidebarListSubitem>
                            )
                        )
                    }>
                    {item.text}
                </SidebarListItem>
            )
        })
    }, [navigationMenu, parentRoute, active, checkAccess, haveAccessToManageStaff])

    if (window.innerWidth < responsiveBreakpointMap.md && !showMobile) {
        return (
            <>
                <DesktopPrompt localeText={localeText}></DesktopPrompt>
            </>
        )
    }

    return (
        <main className="w-full h-full bg-neutrals-100 flex flex-col">
            <div className="flex-grow flex overflow-hidden relative">
                {!hideNavigation && (
                    <Sidebar
                        shrink={active}
                        showMobileSidebar={showMobileSidebar}
                        prepend={
                            <div
                                className={`${
                                    active ? 'px-2 mb-2' : 'px-4'
                                } mt-4 border-b border-neutrals-100 h-[54px]`}>
                                <Select
                                    value={String(getCurrentBusiness())}
                                    onOpenChange={() => setActive(false)}
                                    onValueChange={(value) => {
                                        setCurrentBusiness(Number(value))
                                        const currentBusiness = businesses.find(
                                            (item) => Number(item.businessId) === Number(value)
                                        )

                                        delayedCallback(() => {
                                            window.location.href = `/business/product`
                                        }, 250)
                                    }}>
                                    <SelectTrigger className="bg-white font-semibold">
                                        <SelectValue placeholder="Select a business"></SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        <div className="p-1 w-full">
                                            <Button
                                                variant="outline"
                                                className="w-full gap-2"
                                                onClick={() => router.push('/business')}>
                                                <LucidePlusCircle size={18}></LucidePlusCircle>
                                                <p>{localeText?.common?.add}</p>
                                            </Button>
                                        </div>
                                        <SelectSeparator></SelectSeparator>
                                        {businesses.map((item) => (
                                            <SelectItem key={item.businessId} value={String(item.businessId)}>
                                                <div className="flex items-center truncate flex-grow flex-shrink">
                                                    {item.imageUrl && item.imageUrl.startsWith('https') ? (
                                                        <div className="w-5 h-5 mr-2 rounded-full flex items-center justify-center overflow-hidden bg-neutrals-200 flex-shrink-0">
                                                            <Image src={item.imageUrl} width={20} height={20} alt="" />
                                                        </div>
                                                    ) : (
                                                        <div className="flex-shrink-0 w-5 h-5 mr-2 bg-primary-100 rounded-full"></div>
                                                    )}
                                                    <span className="truncate">{item.name}</span>
                                                </div>
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        }
                        append={
                            <div className="px-4 pb-4">
                                <DropdownWrapper
                                    dropdownMinWidth={200}
                                    onOpen={() => setProfileDropdownState(true)}
                                    onClose={() => setProfileDropdownState(false)}
                                    onSelect={({ value }) => {
                                        if (value === 'logout') {
                                            logout()
                                            return
                                        }

                                        if (value === 'account') {
                                            router.push(`/business/${value}`)
                                            return
                                        }

                                        if (value === 'feedback') {
                                            alert('Feedback option is currently disabled.')
                                        }

                                        if (value === 'contact') {
                                            window.open('https://wa.me/+919929970571', '_blank')
                                        }
                                    }}
                                    options={[
                                        {
                                            text: localeText?.common?.logout,
                                            value: 'logout',
                                            icon: <Icon name="logout"></Icon>
                                        },
                                        {
                                            text: localeText?.common?.setting,
                                            value: 'account',
                                            icon: <Icon name="cog"></Icon>
                                        },
                                        {
                                            text: localeText?.common?.contact_us,
                                            value: 'contact',
                                            icon: <Icon name="chat-outline"></Icon>
                                        }
                                        // {
                                        //     text: localeText?.common?.feedback,
                                        //     value: 'feedback',
                                        //     icon: <Icon name="information-circle"></Icon>
                                        // }
                                    ]}>
                                    <div
                                        role="button"
                                        className={`flex items-center cursor-pointer px-3 py-3 mb-4 rounded-full ${
                                            profileDropdownState ? 'bg-[#FF52001A]' : 'hover:bg-[#FF52001A]'
                                        }`}>
                                        <div className="w-7 h-7 mr-3 rounded-full flex items-center justify-center overflow-hidden bg-neutrals-100 flex-shrink-0">
                                            {profile &&
                                                profile.imageUrl &&
                                                profile.imageUrl.startsWith('https') &&
                                                profile.imageUrl.length > 0 && (
                                                    <Image
                                                        src={profile.imageUrl}
                                                        width={28}
                                                        height={28}
                                                        unoptimized
                                                        alt=""
                                                        onError={() =>
                                                            $sentry.sendSentryEvent(
                                                                'Error loading image: ' + profile.imageUrl
                                                            )
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <div className="truncate">
                                            {profile?.name !== '' ? profile?.name : profile?.phoneNumber}
                                        </div>
                                        <Spacer></Spacer>
                                        <Icon name="dots-vertical" color={COLORS.MAIN.NEUTRALS[400]}></Icon>
                                    </div>
                                </DropdownWrapper>
                                <p className="flex justify-center">
                                    <Link href={'http://simplystock.co'} className="text-sm text-neutrals-400">
                                        simplystock.co
                                    </Link>
                                </p>
                            </div>
                        }>
                        <>
                            {!isSubscribed && (
                                <div
                                    className={`${
                                        active ? 'px-0' : 'p-2'
                                    } hover:bg-amber-50 flex items-center rounded-lg`}
                                    role="button"
                                    onClick={() => execFeature()}>
                                    <Icon name="premium-outline" color={COLORS.SEMANTIC.AMBER['600']} size="xl"></Icon>
                                    <div className="w-3"></div>
                                    <p className="font-bold bg-gradient-to-r to-amber-400 from-amber-600 bg-clip-text inline-block text-transparent underline">
                                        {localeText?.common?.headerDashboard}
                                    </p>
                                </div>
                            )}
                            {sidebarItems}
                        </>
                    </Sidebar>
                )}

                <div className="bg-neutrals-50 relative flex flex-col overflow-hidden flex-grow" id="right-hand-side">
                    {children}
                </div>
            </div>
        </main>
    )
}
