const Manufacture = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
            <title>product-development</title>
            <path d="M101.64,14.77a5.92,5.92,0,0,0-8.52,0l-6.49,6.47a42.27,42.27,0,0,0-5.51-3A57.72,57.72,0,0,0,75.24,16V6A5.77,5.77,0,0,0,73.5,1.74,5.76,5.76,0,0,0,69.24,0H56.75a5.83,5.83,0,0,0-4.2,1.74A5.73,5.73,0,0,0,50.77,6v9.11A45.86,45.86,0,0,0,44.71,17a46.91,46.91,0,0,0-5.59,2.58l-7.19-7.09a5.54,5.54,0,0,0-4.16-1.8,5.92,5.92,0,0,0-4.26,1.8l-8.74,8.77a5.92,5.92,0,0,0,0,8.52l6.47,6.49a42.27,42.27,0,0,0-3,5.51A57.72,57.72,0,0,0,16,47.64H6a5.77,5.77,0,0,0-4.26,1.74A5.76,5.76,0,0,0,0,53.64V66.13a5.83,5.83,0,0,0,1.74,4.2A5.73,5.73,0,0,0,6,72.11h9.11A45.86,45.86,0,0,0,17,78.17a56.7,56.7,0,0,0,2.58,5.72L12.47,91a5.54,5.54,0,0,0-1.8,4.16,5.92,5.92,0,0,0,1.8,4.26l8.77,8.87a6.24,6.24,0,0,0,8.52,0l6.49-6.6a42.27,42.27,0,0,0,5.51,3,57.72,57.72,0,0,0,5.88,2.3v9.95a5.94,5.94,0,0,0,6,6H66.13a5.83,5.83,0,0,0,4.2-1.74,5.73,5.73,0,0,0,1.78-4.26v-9.11a45.86,45.86,0,0,0,6.06-1.87,56.7,56.7,0,0,0,5.72-2.58L91,110.41a5.82,5.82,0,0,0,8.42,0l8.87-8.77a6.24,6.24,0,0,0,0-8.52l-6.6-6.49a42.27,42.27,0,0,0,3-5.51,57.72,57.72,0,0,0,2.3-5.88h9.95a5.94,5.94,0,0,0,6-6V56.75a5.83,5.83,0,0,0-1.74-4.2,5.73,5.73,0,0,0-4.26-1.78h-9.11a51.84,51.84,0,0,0-1.87-6,45,45,0,0,0-2.58-5.65l7.09-7.19a5.54,5.54,0,0,0,1.8-4.16,5.92,5.92,0,0,0-1.8-4.26l-8.77-8.74ZM69.44,43.4l-9.18,5.21L71.38,50l8.37-5.28L69.44,43.4Zm-11.08,8-.44,12.93L53,61l-5,2.81,1-13.56-9.11-1.17V77.84l30.29,3.37V52.92l-11.78-1.5Zm-7.64-4,8.9-5.28-4.4-.57-11.35,5,6.85.87Zm22.45,5.08V80.23L83,74.35l-.2-28-9.66,6.09Zm-.62,31.65a1.53,1.53,0,0,1-.9.29h-.23L38.24,80.71a1.52,1.52,0,0,1-1.42-1.52V47.34a1.52,1.52,0,0,1,.9-1.42L54.38,38.6a1.54,1.54,0,0,1,.8-.12L84,42.26a1.7,1.7,0,0,1,1.87,1.56l.23,31.31a1.51,1.51,0,0,1-.74,1.37L72.55,84.12ZM61.44,25.4a36,36,0,1,1-36,36,36,36,0,0,1,36-36Z" />
        </svg>
    )
}

export default Manufacture
